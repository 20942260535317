export default {
  "Channel": "Channel",
  "KYC": "KYC",
  "accept": "Accept",
  "achievement": "Achievement",
  "action.check_as_done": "Mark action as completed",
  "actions.action_type": "Action type",
  "actions.add": "Add action",
  "actions.call": "Call",
  "actions.date": "Date",
  "actions.datetime": "Date",
  "actions.edit": "Edit action",
  "actions.email": "Email",
  "actions.form": "Form",
  "actions.new": "New action",
  "actions.notes": "Notes",
  "actions.rejection": "Rejection",
  "actions.rejection_title": "Offer rejected",
  "actions.reservation": "Reservation",
  "actions.reservation_title": "Property reserved",
  "actions.select_action_type": "Select action type",
  "actions.sent_marketing_info": "Sent marketing info",
  "actions.sent_marketing_info_title": "Info sent",
  "actions.signed": "Signed",
  "actions.signed_title": "Signed property",
  "actions.staff": "Staff",
  "actions.title": "Title",
  "actions.visit": "Visit",
  "active.users": "Active users",
  "add": "Add",
  "add-file": "Add file",
  "add-files": "Add one or more files",
  "address": "Address",
  "admin": "Admin",
  "advanced_search": "Advanced search",
  "age": "Age",
  "alert-type": "Alert type",
  "all": "all",
  "amount": "Amount",
  "analytics.no-dashboard": "There are no dashboards available",
  "and": "and",
  "any-of": "any of",
  "api-error": "Something is wrong and it's not your fault. Please, contact support with the following information:",
  "app-image": "App Image",
  "app-notifications": "App notifications",
  "apply_filters": "Apply filters",
  "april": "April",
  "april-short": "Apr",
  "attach_files": "Attach files",
  "august": "August",
  "august-short": "Aug",
  "average": "Average",
  "average.visit.time": "Dwell time",
  "back": "Back",
  "bank-account-number": "IBAN",
  "bdate": "Birthday",
  "between": "between",
  "bonusRequest.bonus-shop": "Brand",
  "bonusRequest.depreciation": "Depreciation",
  "bonusRequest.employee-cost": "Employee cost per year",
  "bonusRequest.employee-hours": "Employee hours",
  "bonusRequest.exercise": "Exercise Accounting Period",
  "bonusRequest.franchisee": "Franchisee",
  "bonusRequest.franchisee-expenses": "Franchisee expenses",
  "bonusRequest.month": "Month",
  "bonusRequest.sale-cost": "Sale cost",
  "bonusRequest.sales": "Sales",
  "bonusRequest.supplies": "Supplies",
  "bookable": "Bookable",
  "booking-contract": "Booking contract",
  "booking-price": "Booking price",
  "both": "Both",
  "boy": "Boy",
  "breadcrumbs.main": "Main",
  "building-state": "Building state",
  "built-surface": "Built sur.",
  "bus": "Bus",
  "cadastral-reference": "Cadastral reference",
  "calendar": "Calendar",
  "calibrator": "Calibrator",
  "call-to-action": "Call to action",
  "campaign": "Campaign",
  "campaign-report.all-shops": "All stores",
  "campaign-report.campaign-actions": "Campaign actions",
  "campaign-report.campaign-cost": "Campaign cost",
  "campaign-report.campaign-goals": "Campaign goals",
  "campaign-report.campaign-kpi": "Campaign kpi",
  "campaign-report.campaign-type": "Campaign type",
  "campaign-report.description": "Description",
  "campaign-report.estimated-cost": "Estimated cost",
  "campaign-report.final-cost": "Final cost",
  "campaign-report.final-cost-in-estimated": "Within bugdet",
  "campaign-report.final-cost-over-estimated": "Over budget",
  "campaign-report.more-shops": "+ {{shops}} stores",
  "campaign-report.notes": "Notes",
  "campaign-report.rating": "Campaign rating",
  "campaign-report.reference-period": "Reference period",
  "campaign-report.select-visible-kpis": "Select visible KPIs",
  "campaign-report.shops": "Stores",
  "campaign-report.suppliers-rating": "Suppliers rating",
  "campaign.filters": "Filters",
  "campaign.status.active": "Active",
  "campaign.status.closed": "Closed",
  "campaign.status.finished": "Finished",
  "campaign.status.not-redeemable": "Not redeemable",
  "campaign.status.planned": "Planned",
  "campaign.status.redeemable": "Redeemable",
  "campaign_id": "Campaign",
  "campaigns": "Campaings",
  "campaigns.new": "New campaign",
  "campaigntype": "Campaign type",
  "cancel": "Cancel",
  "category": "Category",
  "cbre-contact": "CBRE Contact",
  "center": "Center",
  "center-id": "Center id",
  "center.not_found": "Centres not found",
  "center.select_one": "Select one centre",
  "centers": "Centers",
  "centers-required": "Centers is required",
  "change-password.confirm-password": "Confirm new password",
  "change-password.new-password": "New Password",
  "change-password.old-password": "Current password",
  "change-password.title": "Change password",
  "channel-required": "The channel is required",
  "channel_id": "Channel",
  "channels": "Channels",
  "channels.app": "App",
  "channels.other": "Other",
  "channels.play-center": "Play center",
  "channels.social-wifi": "Social wifi",
  "channels.web": "Web",
  "check-in": "Check in",
  "child": "Child",
  "child.check-out": "Check out",
  "children": "Children",
  "children-count": "{{count}} child",
  "children-count_plural": "{{count}} children",
  "children-number": "Children number",
  "cif": "CIF",
  "city": "City",
  "clear": "Clear",
  "clear_filters": "Clear",
  "closed": "Closed",
  "cms.commerce-stay-alert.correction": "Correction",
  "cms.commerce-stay-alert.stays-full": "Stays full",
  "cms.proposal.active": "Active",
  "cms.proposal.draft": "Draft",
  "cms.proposal.expired": "Expired",
  "cms.proposal.pending": "Pending",
  "cms.proposal.scheduled": "Scheduled",
  "commerce": "Store",
  "commerce-stay-alert.filters": "Filters",
  "commerces": "Stores",
  "commercialization": "Commercialisation",
  "commercialization-start": "Commercialisation start",
  "common-zones-surface": "Common zones sur.",
  "communication.roles.commerce": "Store",
  "communication.roles.management": "Management",
  "communication.roles.services": "Services",
  "communication.state.accepted": "Accepted",
  "communication.state.pending": "Pending",
  "communication.state.rejected": "Rejected",
  "communication.state.solved": "Solved",
  "communication.type.access": "Access",
  "communication.type.issues": "Issues",
  "communication.type.marketing": "Marketing",
  "communication.type.news": "News",
  "communication.type.tabs.inbox": "Inbox",
  "communication.type.tabs.sent": "Sent",
  "community-expenses": "Community expenses",
  "company": "Company",
  "concepts.airplane_tickets": "Airplane tickets",
  "concepts.bar": "Bar",
  "concepts.cancellations": "Cancellations",
  "concepts.cinema_tickets": "Cinema tickets",
  "concepts.coffee": "Coffee",
  "concepts.drones": "Drones",
  "concepts.equipment": "Machinery (equipment)",
  "concepts.fees_delivery": "Fees delivery",
  "concepts.free_devices": "Free Devices",
  "concepts.game": "Game",
  "concepts.general": "General",
  "concepts.general_variable": "General Merchandise Variable Rent",
  "concepts.gm1": "General Merchandise 1",
  "concepts.gm2": "General Merchandise 2",
  "concepts.magazines": "Newspaper and magazines",
  "concepts.online_sales": "Online sales",
  "concepts.phonecard": "Phonecard",
  "concepts.prescription": "Prescription",
  "concepts.pub": "Pub",
  "concepts.radio-modelism": "Radio modelism",
  "concepts.receipts": "Uploads / Invoices",
  "concepts.rent_car_services": "Rent car services",
  "concepts.school_mat": "School Material",
  "concepts.stamps_and_values": "stamps and values",
  "concepts.telecommunications": "Services Telecommunications",
  "concepts.tobacco": "Tobacco",
  "concepts.tobacco_game": "Tobacco and Game",
  "concepts.trans_currency": "Trans. Currency",
  "concepts.uploads_of_credit": "Uploads of credit",
  "configuration.staff-member.notification": "I want to receive intranet communications by email",
  "confirm-modal.cancel": "Cancel",
  "confirm-modal.ok": "Accept",
  "confirm-password": "Confirm password",
  "contactable": "Contactable",
  "contains": "contains",
  "countries": "Countries",
  "country": "Country",
  "country-required": "Country is required",
  "couple": "With partner",
  "cp": "Postal code",
  "create": "Create",
  "create-new": "Create",
  "created_at": "Register date",
  "crm-manager": "CRM Manager",
  "crm-operator": "CRM Operator",
  "crm.children.bdate": "Birthday date",
  "crm.children.gender": "Gender",
  "crm.children.name": "Name",
  "crm.filter.type_find": "Type to find filters",
  "crm.filters-section.crm": "CRM",
  "crm.filters-section.demographic": "Demographic",
  "crm.filters-section.requests": "Requests",
  "crm.segments": "Segments",
  "crm.user.remove-email": "Send an email to contact notifying that has been remove of the system",
  "crm.users.bdate-month-day": "Birthdate (range)",
  "crm.users.bdate-today": "Birthdate (today)",
  "crm.users.children-bdate-month-day": "Children birthday (range)",
  "crm.users.code": "Code",
  "crm.users.email": "Email",
  "crm.users.go-to-users-list": "Go to Users list",
  "crm.users.last-action": "Last Action",
  "crm.users.last-action-datetime": "Last action date",
  "crm.users.last_name": "Last name",
  "crm.users.name": "Name",
  "crm.users.not-email": "Do not have email",
  "crm.users.phone": "Phone",
  "crm.users.state": "State",
  "crm.users.user-saved": "The user has been saved with success!",
  "crm.users.vat-number": "VAT number",
  "csv": "CSV",
  "currency": "Currency",
  "current-pass-required": "Current pass is required",
  "current-value": "Current value",
  "currently-in-playcenter": "Currently in play center",
  "dashboard": "Dashboard",
  "dashboard-list.dashboards.not-found": "Dashboards not found",
  "dashboard-list.title.active-graphs": "Active graphs",
  "dashboard.category.general": "General",
  "dashboard.category.home": "Home",
  "dashboard.category.traffic": "Traffic",
  "dashboard.error.not-found": "Dashboard not found",
  "dashboard.external-url": "External URL",
  "dashboard.index.no-home-dashboard": "Welcome to Neuromobile Back Office",
  "dashboards": "Dashboards",
  "dashboards.0-17": "< 18",
  "dashboards.1": "1",
  "dashboards.18-24": "18-24",
  "dashboards.2": "2",
  "dashboards.25-34": "25-34",
  "dashboards.3": "3",
  "dashboards.35-44": "35-44",
  "dashboards.4": "4",
  "dashboards.45-99": "> 45",
  "dashboards.active-users": "Active users",
  "dashboards.active-users-chart": "Active Users vs % Active Users (Month)",
  "dashboards.active-vs-registered-users-chart": "Active Users vs Registered Users",
  "dashboards.age-range": "Age range",
  "dashboards.app-users": "App users",
  "dashboards.channels.mail-notifications": "Registered users with active mail notifications",
  "dashboards.channels.verified-mail": "Registered users with verified mail",
  "dashboards.couple": "Couple",
  "dashboards.dwell-chart": "Dwell Time vs Competition (Month)",
  "dashboards.dwell-time": "Dwell time",
  "dashboards.edit": "Edit dashboard",
  "dashboards.email": "Valid email",
  "dashboards.email-notifications": "Mail notifications",
  "dashboards.family": "Family",
  "dashboards.female": "Female",
  "dashboards.frequency-chart": "Visits Recurrence vs Competition (Month)",
  "dashboards.gender": "Gender",
  "dashboards.influence-zone": "Influence zone",
  "dashboards.influence-zones": "Influence zones",
  "dashboards.mail-notif-consent": "Mail notifications",
  "dashboards.mail-partner-consent": "Third party notifications",
  "dashboards.male": "Male",
  "dashboards.marital-status": "Marital status",
  "dashboards.men": "Man",
  "dashboards.new": "New dashboard",
  "dashboards.new-app-users": "Registered App Users",
  "dashboards.new-registered-users": "Registered Users",
  "dashboards.none": "None",
  "dashboards.other": "Other",
  "dashboards.other-users": "Other users",
  "dashboards.per-active-users": "% Active Users",
  "dashboards.phone": "Phone",
  "dashboards.play-center-users": "Play center users",
  "dashboards.quality-data-chart": "Quality of user data",
  "dashboards.recurrence": "Visit Recurrence",
  "dashboards.registered-app-users": "Registered app users",
  "dashboards.registered-total-users-chart": "App Registries vs Total Registries (Aggregate)",
  "dashboards.registered-users": "Total registered users",
  "dashboards.registered-users-chart": "App Registries vs Total Registries (Month)",
  "dashboards.registered-users-total": "Total Registered Users",
  "dashboards.registers-per-day": "Monthly registers per day",
  "dashboards.registration-rate": "Registration Rate",
  "dashboards.registration-rate-chart": "Registration Rate (Month)",
  "dashboards.registration-vs-consent-chart": "Registration vs Consent",
  "dashboards.rsocial-users": "Social wifi users",
  "dashboards.single": "Single",
  "dashboards.thirt-party-notifications": "Third party notifications",
  "dashboards.total-app-users": "Total Registered App Users",
  "dashboards.total-registered-users": "Total Registered Users",
  "dashboards.user-profiles-age-chart": "Age",
  "dashboards.user-profiles-catchment-area-chart": "Catchment Area",
  "dashboards.user-profiles-family-chart": "Family",
  "dashboards.user-profiles-gender-chart": "Gender",
  "dashboards.vat-number": "Vat number",
  "dashboards.visits": "Visits",
  "dashboards.visits-competition-chart": "Visits vs Competition (Month)",
  "dashboards.visits-users-chart": "Visits vs Active users",
  "dashboards.web-users": "Web users",
  "dashboards.weekly.database-increase": "New registered users per channel",
  "dashboards.weekly.downloads": "Downloads",
  "dashboards.weekly.downloads-chart": "Downloads (Week)",
  "dashboards.weekly.dwell-time-chart": "Dwell time (Week)",
  "dashboards.weekly.last-eight-weeks-chart": "Registered users since 8 weeks ago per channel",
  "dashboards.weekly.registered-users-channel": "Registered users per channel",
  "dashboards.weekly.total-registers": "Total registered users",
  "dashboards.weekly.user-profile-selected-week": "User's profile of the selected week",
  "dashboards.weekly.user-profile-year-average": "Year average of user's profile",
  "dashboards.weekly.year-average": "Average of the year",
  "dashboards.with-family": "With family",
  "dashboards.with-partner": "With partner",
  "dashboards.women": "Woman",
  "dashboards.zip-code": "Postal code",
  "dashboards.zone1": "Zone 1",
  "dashboards.zone2": "Zone 2",
  "dashboards.zone3": "Zone 3",
  "dashboards.zone4": "Zone 4",
  "date": "Date",
  "date.from": "From",
  "date.from-to": "From {{from}} to {{to}}",
  "date.to": "To",
  "days": "Days",
  "december": "December",
  "december-short": "Dec",
  "default-building": "Default building",
  "default-building-required": "A default building is required",
  "delete": "Delete",
  "restore": "Restore",
  "delivery-date": "Delivery date",
  "demographic": "Demographic",
  "description": "Description",
  "developer-contact": "Developer contact",
  "development": "Development",
  "development-maps": "Development floor plan",
  "development.built_surface": "Built sur.",
  "development.city": "City",
  "development.edit": "Edit development",
  "development.name": "Development",
  "development.new": "New development",
  "development.useful_surface": "Useful sur.",
  "discharge-date": "Discharge date",
  "dmr-weekly.select-week": "Select week",
  "dni": "DNI",
  "doc": "Doc",
  "document": "Document",
  "documentation": "Documentation",
  "dollars": "Dollars $",
  "done": "Done",
  "download": "Download",
  "download-files": "Download files",
  "downloads": "Downloads",
  "draft": "Draft",
  "draft-bookable": "Draft-bookable",
  "duplicate": "Duplicate",
  "east": "East",
  "ecop": "ECOP",
  "edit": "Edit",
  "edit-staff": "Edit staff",
  "email": "Email",
  "email-campaign": "E-mail campaign",
  "email-campaign.active": "Active",
  "email-campaign.archived": "Archived",
  "email-campaign.campaign.list": "Campaigns",
  "email-campaign.daily": "Daily",
  "email-campaign.date": "Date",
  "email-campaign.draft": "Draft",
  "email-campaign.edit": "Edit e-mail campaign",
  "email-campaign.end-date": "End date",
  "email-campaign.form.desing_template": "Design",
  "email-campaign.form.general_info": "General info",
  "email-campaign.form.summary": "Summary",
  "email-campaign.frequency-sending": "Sending frequency",
  "email-campaign.hourly": "Hourly",
  "email-campaign.htmlBody.design": "Design your e-mail",
  "email-campaign.htmlBody.file_import": "Import template",
  "email-campaign.htmlBody.init_greeting": "Edit your e-mail here :)",
  "email-campaign.htmlBody.params": "E-mail params",
  "email-campaign.htmlBody.preview": "Preview",
  "email-campaign.name": "Campaign name",
  "email-campaign.new": "New e-mail campaign",
  "email-campaign.not_found": "E-mail campaigns not found",
  "email-campaign.params.help.html": "Insert this codes to add vars in HTML template",
  "email-campaign.params.help.subject": "Insert this codes to add vars in e-mail subject",
  "email-campaign.params.html.center_name": "Add the centre name",
  "email-campaign.params.html.unsub-link": "Add the unsubscribe link",
  "email-campaign.params.html.user_email": "Add the user e-mail",
  "email-campaign.params.html.user_name": "Add the user name",
  "email-campaign.params.subject.barcode_image": "Add the barcode image",
  "email-campaign.params.subject.center_name": "Add the centre name",
  "email-campaign.params.subject.qr_image": "Add the qr image",
  "email-campaign.params.subject.reward-code": "Add the reward code",
  "email-campaign.params.subject.user_code": "Add the user code",
  "email-campaign.params.subject.user_email": "Add the user e-mail",
  "email-campaign.params.subject.user_name": "Add the user name",
  "email-campaign.scheduled": "Scheduled",
  "email-campaign.segment": "Segment",
  "email-campaign.segment.list": "Segments",
  "email-campaign.segment.segmentName": "Segment name",
  "email-campaign.send.save_and_test": "Save and send test",
  "email-campaign.sending-date": "Sending date",
  "email-campaign.sentDatetime": "Sent date",
  "email-campaign.single-sending": "Single sending",
  "email-campaign.start-date": "Start date",
  "email-campaign.statistic.blocked": "Blocked",
  "email-campaign.statistic.bounced": "Bounced",
  "email-campaign.statistic.clicked": "Clicked",
  "email-campaign.statistic.delivered": "Delivered",
  "email-campaign.statistic.opened": "Opened",
  "email-campaign.statistic.spam": "Spam",
  "email-campaign.subject": "Subject",
  "email-campaign.warning-save-draft": "Before sending a test email, the campaign is going to be saved in draft state",
  "email-campaign.warning-unsub-link": "Warning! The current template doesn't have an unsubscribe link. Please, add one before publishing the campaign.",
  "email-campaign.weekly": "Weekly",
  "email-required": "Email is required",
  "email-template.select_one": "Select one template",
  "email-template.send.select_mail": "Type the e-mail address you want to send the test e-mail to",
  "email-template.send.test": "Send test e-mail",
  "email-template.type.send_contact": "Message from mobile app",
  "email-template.type.send_intranet_communication_email": "Intranet communication notification",
  "email-template.type.send_proposals_report": "Coupons report",
  "email-template.type.send_reprint_tickets": "Tickets reprint notification",
  "email-template.type.send_staff_connection_warning": "Warning staff connection",
  "email-template.type.send_staff_reset_password_email": "Reset staff's password reminder",
  "email-template.type.send_user_activate_email": "Mobile app user activation message",
  "email-template.type.send_user_child_birthday_reminder": "Customer's child birthday reminder",
  "email-template.type.send_user_child_birthday_visit_ack": "Customer's child after birthday visit acknowledgement",
  "email-template.type.send_user_registration_email": "Customer registration message",
  "email-template.type.send_user_reset_password_email": "Mobile app user password reset message",
  "email-template.type.send_user_unsubscribe_email": "Unsubscribe concact email ",
  "email.not-exist": "Email doesn't exist",
  "email.recovery-sent": "The email to recover the password has been sent successfully",
  "emailing-campaign.filters": "Filters",
  "emailing.campaign.import-template": "Import template",
  "emailing.campaigns.copy-of": "Copy of",
  "emailing.campaigns.duplicate-error": "Error on duplicating campaign",
  "emailing.campaigns.stop-error": "Error on stopping campaign",
  "end-date": "End date",
  "energy-certification": "Energy certification",
  "entry-time": "Entry time",
  "error.channels.required": "Channels is required",
  "error.child.bdate.required": "Birthday is required",
  "error.child.name.required": "Name is required",
  "error.child.relationship.required": "Relationship is required",
  "error.child.sex.required": "Gender is required",
  "error.commerce.categories.required": "A category is required",
  "error.commerce.gender.required": "A gender is required",
  "error.commerce.imageUrl.required": "Image url is required",
  "error.commerce.name.required": "Name is required",
  "error.commerce.whatsapp-message.required": "A WhatsApp Message is required",
  "error.commerce.whatsapp-phone.format": "WhatsApp phone doesn't have a code area. It should follow the format +346xxxxxxxx.",
  "error.commerce.zone.required": "Zone is required",
  "error.communication.commerce.required": "Select at least one store",
  "error.communication.companion.required": "Some companions have blank data",
  "error.coupon.commerces.required": "Store required",
  "error.coupon.text.required": "Text is required",
  "error.coupon.usageLimit.required": "Coupon limit is required",
  "error.crm.children.entryTime.required": "Entry time is required",
  "error.crm.children.expectedExitTime.required": "Expected exit time is required",
  "error.dashboard.category.required": "Category is required",
  "error.dashboard.name.required": "Name is required",
  "error.dashboard.panel.required": "Dashboard panel is required",
  "error.dashboard.url.required": "URL is required",
  "error.development.required": "Development is required",
  "error.email-campaign-template.htmlBody.required": "Campaign template can't be blank",
  "error.email-campaign-template.name.required": "Name can't be blank",
  "error.email-campaign.date.invalid_past": "Start date can't be in the past",
  "error.email-campaign.date.required": "Date can't be blank",
  "error.email-campaign.endDatetime.post_startDatetime": "End date must be after start date",
  "error.email-campaign.htmlBody.required": "Campaign template can't be blank",
  "error.email-campaign.name.required": "Name can't be blank",
  "error.email-campaign.segment.required": "Segment field can't be blank",
  "error.email-campaign.subject.required": "E-mail subject can't be blank",
  "error.email.invalid": "Invalid e-mail address",
  "error.file.commerces.required": "Select at least one store",
  "error.file.name.required": "Name can't be blank",
  "error.file.not_allowed": "File not allowed",
  "error.file.roles.required": "Select a role",
  "error.file.type.not_allowed": "File type not allowed",
  "error.file.url.required": "File is required",
  "error.proposal.category.required": "Category is required",
  "error.proposal.channel.required": "At least one channel must be selected",
  "error.proposal.description.required": "Description is required",
  "error.proposal.end-date.after": "End date can not be before start date",
  "error.proposal.end-date.required": "End date required",
  "error.proposal.event-end-date.after": "Event end date can not be before event start date",
  "error.proposal.event-end-date.required": "Event end date required",
  "error.proposal.eventStartDate.required": "Event startDate is required",
  "error.proposal.gender.required": "Gender is required",
  "error.proposal.maximumAge.required": "Maximum age is required",
  "error.proposal.minimumAge.required": "Minimum age is required",
  "error.proposal.name.required": "Name required",
  "error.proposal.proposalType.required": "Type is required",
  "error.proposal.startDate.required": "StartDate is required",
  "error.push-notification.event-end-date.after": "After",
  "error.residential.owner.code.required": "Code is required",
  "error.residential.owner.name.required": "Name is required",
  "error.sale.date.closed-month-error": "You can't add sales in closed months",
  "error.sale.date.invalid_future": "You can't add sales of future dates",
  "error.server": "Server error",
  "error.signature.not_exist": "The user does not have signature",
  "error.tickets.amount.required": "Amount is required",
  "error.tickets.commerce.required": "Store is required",
  "error.tickets.date.required": "Date is required",
  "error.user.cp.required": "Postal code is required",
  "error.user.email.required": "Email is required",
  "error.user.gdpr": "The GPDR should be accepted",
  "error.user.lastName.required": "Last name is required",
  "error.user.name.required": "Name is required",
  "error.user.personType.required": "Person type is required",
  "error.user.phone.required": "Phone is required",
  "error.user.registration-date.required": "Registration date is required",
  "error_browser_iframe": "Your browser does not support iframes",
  "estate": "Estate",
  "estimated-cost": "Estimated cost",
  "eula": "EULA",
  "euros": "Euros €",
  "excel": "Excel",
  "exit-time": "Exit time",
  "external-api": "External API",
  "external-url": "External URL",
  "extra-info": "Extra info",
  "family": "With family",
  "featured": "Featured",
  "february": "February",
  "february-short": "Feb",
  "file.roles.all": "All",
  "file.roles.commerce": "Store",
  "file.roles.management": "Management",
  "file_max_allowed": "The maximum allowed size is {{size}}",
  "filter.select": "Select a filter",
  "final-cost": "Final cost",
  "first-property": "First property",
  "floor": "Floor",
  "floor-plans": "Floor plans",
  "folder": "Folder",
  "folder.add": "Create folder",
  "folder.new.current_dir": "Create a new folder",
  "folder_name": "Folder name",
  "following": "the following",
  "forgot-password": "Forgot password?",
  "friday": "Friday",
  "friday-short": "Fri",
  "from-unit-delivery-date": "From unit delivery date",
  "from-unit-opening-date": "From unit opening date",
  "garage-plan": "Garage plans",
  "garages-number": "Garage number",
  "general-data": "General data",
  "general-info": "General info",
  "general-terms-of-online-contracting": "General terms of online contracting",
  "girl": "Girl",
  "goal-value": "Goal value",
  "grandchild": "Grandchild",
  "has-email": "Has email",
  "header": "Header",
  "how-to-arrive-plan": "Plan \"How to arrive\"",
  "id": "id",
  "image": "Image",
  "image-required": "Image is required",
  "images": "Images",
  "import": "Import",
  "info.default.error.message": "An error occurred. Please try again later",
  "info.default.error.title": "Error",
  "info.default.succes.title": "Success",
  "info.default.success.message": "The operation was executed succesfully.",
  "informative-message.change-password.success.message": "Your password has been changed. You will be redirected to the login panel",
  "informative-message.export-crm-csv.success.message": "You will receive an email with the CSV file",
  "informative-message.upload-yardi.error": "The Yardi file hasn't been uploaded correctly. Please, try again or contact with an admin.",
  "informative-message.upload-yardi.success": "The Yardi file has been uploaded correctly.",
  "insert-email": "Insert your email address",
  "installed.apps": "Registered apps",
  "internal": "Internal",
  "intranet.communications.accept-eula": "Yes, I wish to receive electronic communications from third party.",
  "intranet.communications.contact-it": "There was an error while accepting third party communications. Please, contact with IT and send to them the following trace error:",
  "intranet.communications.error": "Error updating staff member",
  "intranet.communications.eula-communications-description": "Before using the intranet communication's system, you need to accept the terms and conditions.",
  "intranet.communications.terms-and-conditions": "Terms and conditions",
  "intranet.communications.title-eula": "Intranet Communications - RGPD acceptance",
  "intranet.sales-years.status.pending": " Pending",
  "intranet.sales-years.status.rejected": "Rejected",
  "intranet.sales-years.status.unrequested": "Unrequested",
  "intranet.sales-years.status.validated": "Validated",
  "invalid-token": "The token is invalid",
  "investor": "Investor",
  "is": "is",
  "is_not": "is not",
  "january": "January",
  "january-short": "Jan",
  "job-title": "Job title",
  "job-title-required": "Job title is required",
  "july": "July",
  "july-short": "Jul",
  "june": "June",
  "june-short": "Jun",
  "kpi": "Kpi",
  "kpi-tech": "KPI Tech",
  "kpi.tooltip.active-users": "Number of users that have the app active in the selected period",
  "kpi.tooltip.app-users": "Number of registered users via app channel in the selected period",
  "kpi.tooltip.average-visit-time": "Average stay time in the mall in the selected period",
  "kpi.tooltip.downloads": "App downloads in the selected period",
  "kpi.tooltip.dwell-time": "Average stay time in the mall in the selected period",
  "kpi.tooltip.installed-apps": "Number of registered apps in the selected period",
  "kpi.tooltip.play-center-users": "Number of registered users via play center channel in the selected period",
  "kpi.tooltip.recurrence": "Average number of times that users visit the mall in the selected period",
  "kpi.tooltip.registered-app-users": "New users registered by the app channel in the selected period",
  "kpi.tooltip.registered-users-total": "Number of registered users in the selected period",
  "kpi.tooltip.rother-users": "Number of registered users via other channels in the selected period",
  "kpi.tooltip.social-users": "Number of registered users via social wifi channel in the selected period",
  "kpi.tooltip.visits": "Number of visits to the mall in the selected period",
  "kpi.tooltip.web-users": "Number of registered users via web channel in the selected period",
  "kpi.vs-last-month": "VS Last month",
  "kpi.vs-same-month-last-year": "VS Same month last year",
  "kyc": "KYC",
  "label": "Label",
  "last-activity": "Last Activity",
  "last-name": "Last name",
  "last-name-required": "Last name is required",
  "last-units": "Last units",
  "leasing": "Leasing",
  "leasing.accessories": "Accesories",
  "leasing.books": "Books",
  "leasing.bowling": "Bowling",
  "leasing.childrens-apparel": "Childrens apparel",
  "leasing.cinema": "Cinema",
  "leasing.coffeeshop-icecream": "Coffeeshop icecream",
  "leasing.electronics": "Electronics",
  "leasing.fast-food": "Fast food",
  "leasing.food": "Food",
  "leasing.furniture": "Furniture",
  "leasing.hairdressers-and-beauty-services": "Haridressers and beauty services",
  "leasing.handbags": "Handbags",
  "leasing.home": "Home",
  "leasing.home-apparel": "Home apparel",
  "leasing.jewelry-watches": "Jewelry watches",
  "leasing.lingerie": "Lingerie",
  "leasing.mens-apparel": "Mens apparel",
  "leasing.opticians-farmacy": "Opticians farmacy",
  "leasing.other-leisure": "Other leisure",
  "leasing.other-services": "Other services",
  "leasing.outlet": "Outlet",
  "leasing.perfumery-beauty-store": "Perfumery beauty store",
  "leasing.pets": "Pets",
  "leasing.phone-photo-games": "Phone photo games",
  "leasing.restaurants": "Restaurants",
  "leasing.shoes": "Shoes",
  "leasing.special-food": "Special food",
  "leasing.speciality-gift": "Speciality gift",
  "leasing.sports": "Sports",
  "leasing.supermarket": "Supermarket",
  "leasing.toys": "Toys",
  "leasing.travels": "Travels",
  "leasing.womens-apparel": "Womens apparel",
  "leasing.womens-mens-apparel": "Womens & mens apparel",
  "leasing.young-apparel": "Young apparel",
  "legal": "Legal",
  "legal-person": "Legal person",
  "level": "Level",
  "level-required": "Level is required",
  "lineitem": "Line item",
  "location": "Location",
  "lock": "Block",
  "loyalty-card": "Loyalty Card",
  "loyalty-card.campaign": "Campaign",
  "loyalty-card.stamps": "Stamps",
  "loyalty-card.stamps.redeemable-stamp": "Redeemable stamp",
  "loyalty-card.stamps.value": "Value",
  "loyalty-cards": "Loyalty Cards",
  "loyalty-cards.can-not-generate-message": "The user doesn't have any stamps that can be redeemed.",
  "loyalty-cards.create-historic-stamps": "Create stamps",
  "loyalty-cards.error-stamp-number": "Number of stamps (max: {{max}} stamps)",
  "loyalty-cards.generate-error-message": "There was a problem when generating the loyalty cards and they haven't beend created. Please, contact with the support team.",
  "loyalty-cards.generate-success-message": "The loyalty cards has been generated correctly.",
  "loyalty-cards.not-enough-stamps": "The client doesn't have enought stamps to redeem for a loyalty card",
  "loyalty-cards.preview-message": "Loyalty cards summary",
  "loyalty-cards.select-campaign": "Select campaign",
  "loyalty-cards.stamp-number": "Stamp number",
  "loyalty-cards.stamps": "Stamps",
  "loyalty.exchange-stamps": "Reedem stamps",
  "mail-notifications": "Electronic commercial communications",
  "mail_notifications": "Electronic commercial communications",
  "mail_partners_notifications": "Electronic commercial communications from thirds",
  "man": "Man",
  "management": "Management",
  "manager": "Manager",
  "march": "March",
  "march-short": "Mar",
  "marketing": "Marketing",
  "marketing-analytics.campaign.rating": "Campaign rating",
  "marketing-analytics.campaigns.actions": "Campaign actions",
  "marketing-analytics.kpi.active-users": "DMI - Active users",
  "marketing-analytics.kpi.attendant-number": "Campaign - Attendant",
  "marketing-analytics.kpi.contestants": "Campaign - Contestants",
  "marketing-analytics.kpi.cost-per-contestant": "Campaign - Cost / contestant",
  "marketing-analytics.kpi.coupon-exchanges": "DMI - Coupon use",
  "marketing-analytics.kpi.coupon-percent": "DMI - Coupon(%)",
  "marketing-analytics.kpi.eca-number": "Campaign - Publicity",
  "marketing-analytics.kpi.impressions-number": "DMI - Impressions",
  "marketing-analytics.kpi.new-registered-users": "Campaign - New Users",
  "marketing-analytics.kpi.new-registered-users-percent": "Campaign - New Users (%)",
  "marketing-analytics.kpi.previous-registered-users": "Campaign - Previous Registered Users",
  "marketing-analytics.kpi.receipt-average-price": "Campaign - Average receipt",
  "marketing-analytics.kpi.tooltip.active-users": "Amount of active users on the mobile app during the campaign period",
  "marketing-analytics.kpi.tooltip.attendant": "Total number of people who have attended the event",
  "marketing-analytics.kpi.tooltip.contestants": "Total number of people who participated in the event",
  "marketing-analytics.kpi.tooltip.cost-per-contestant": "Average value of the campaign cost per participant",
  "marketing-analytics.kpi.tooltip.coupon-exchanges": "Amount of redeemed coupons",
  "marketing-analytics.kpi.tooltip.coupon-percent": "Redeemed coupons vs total coupons",
  "marketing-analytics.kpi.tooltip.eca": "Unpaid advertising value of the campaign",
  "marketing-analytics.kpi.tooltip.eceipt-average-price": "Average value of receipts during the campaign per contestant",
  "marketing-analytics.kpi.tooltip.impressions-number": "Amount of impressions on the mobile app during the campaign period",
  "marketing-analytics.kpi.tooltip.new-registered-users": "New registered users during the campaign",
  "marketing-analytics.kpi.tooltip.new-registered-users-percent": "New registered users during the campaign vs previously registered users",
  "marketing-analytics.kpi.tooltip.previous-registered-users": "Amount of previously registered users that have taken part in the campaign",
  "marketing-analytics.kpi.tooltip.total-receipts": "Amount of registered receipts and total value",
  "marketing-analytics.kpi.tooltip.total-receipts-value": "Total value of registered receipts",
  "marketing-analytics.kpi.tooltip.visits": "Amount of visits to the mall of customers with the mobile app",
  "marketing-analytics.kpi.total-receipts": "Campaign - Receipts",
  "marketing-analytics.kpi.total-receipts-value": "Campaign - Receipts value",
  "marketing-analytics.kpi.visits": "DMI - Visits",
  "marketing-dossier": "Marketing dossier",
  "match-pass": "The password fields do not match",
  "max-image-size-1440-800": "The recommended header image size is 1440 x 800px.",
  "max-image-size-300-232": "The recommended thumbnail image size is 300 x 232px.",
  "may": "May",
  "may-short": "May",
  "menu.navbar.analytics": "Analytics",
  "menu.navbar.bi": "Marketing analytics",
  "menu.navbar.bi.campaign.index": "Campaigns",
  "menu.navbar.bi.lineitem.index": "Line items",
  "menu.navbar.bi.service.index": "Services",
  "menu.navbar.bi.supplier.index": "Suppliers",
  "menu.navbar.category.new": "New category",
  "menu.navbar.category.show": "Categories",
  "menu.navbar.center.new": "New centre",
  "menu.navbar.cms": "CMS",
  "menu.navbar.cms.commerce-stay-alert.index": "Commerce stay alert",
  "menu.navbar.configuration": "Configuration",
  "menu.navbar.configuration.catalog": "Catalog",
  "menu.navbar.configuration.center": "Centre info",
  "menu.navbar.configuration.dashboards": "Dashboards",
  "menu.navbar.configuration.extra": "Extra attributes",
  "menu.navbar.configuration.influencezone": "Zones of influence",
  "menu.navbar.configuration.module": "Modules",
  "menu.navbar.configuration.password": "Change password",
  "menu.navbar.configuration.shop.import": "Import stores",
  "menu.navbar.configuration.zone": "Zones",
  "menu.navbar.consumption.show": "Consumption",
  "menu.navbar.coupon.report": "Coupons",
  "menu.navbar.customer.show": "Customers",
  "menu.navbar.desktop": "Desktop",
  "menu.navbar.emailing": "Mailing",
  "menu.navbar.emailing.campaign.index": "Email campaigns",
  "menu.navbar.logout": "Logout",
  "menu.navbar.proposal.new": "New proposal",
  "menu.navbar.proposal.show": "Proposals",
  "menu.navbar.proposal.show.active": "Active proposals",
  "menu.navbar.proposal.show.draft": "Draft proposals",
  "menu.navbar.proposal.show.expired": "Expired proposals",
  "menu.navbar.proposal.show.pending": "Pending proposals",
  "menu.navbar.proposal.show.scheduled": "Planned proposals",
  "menu.navbar.push-notification.index": "Push notifications",
  "menu.navbar.recommendation.show": "Recommendations",
  "menu.navbar.residential": "Residential",
  "menu.navbar.residential.campaigns.index": "Campaigns",
  "menu.navbar.residential.developments.index": "Developments",
  "menu.navbar.residential.owners.index": "Owners",
  "menu.navbar.residential.properties.index": "Properties",
  "menu.navbar.shop.show": "Stores list",
  "menu.navbar.staff.new": "New user",
  "menu.navbar.staff.show": "Users",
  "menu.navbar.traffic.blacklist": "Users without tracking",
  "menu.navbar.traffic.show": "Traffic",
  "monday": "Monday",
  "monday-short": "Mon",
  "month": "Month",
  "month.state": "Month state",
  "mstatus": "Marital status",
  "multi_family": "Multi family",
  "multimedia": "Multimedia",
  "multimedia-files": "Multimedia files",
  "name": "Name",
  "name-required": "Name is required",
  "name_required": "Name can't be blank",
  "nationality": "Nationality",
  "nationality_id": "Nacionality",
  "nephew": "Nephew",
  "new-staff": "New staff",
  "new_folder": "New folder",
  "nif": "NIF",
  "no": "No",
  "no-data": "There is no data available with the selected filters",
  "none": "None",
  "north": "North",
  "not-found": "Not found",
  "not_between": "not between",
  "not_contains": "not contains",
  "november": "November",
  "november-short": "Nov",
  "occupation-percentage": "Occupation percentage",
  "october": "October",
  "october-short": "Oct",
  "on-signing-date": "On signing date",
  "open": "Open",
  "options_not_found": "Options not found",
  "or_less": "or less",
  "or_more": "or more",
  "other": "Other",
  "other-data": "Other tata",
  "others": "Others",
  "overview": "Overview",
  "owner": "Owner",
  "owner-code": "Owner code",
  "pager.out_of": "out of",
  "panel": "Panel",
  "parameters": "Parameters",
  "pass": "Password",
  "pass-format": "Use 10 or more letters, with at least 1 number, 1 capital letter and 1 lower letter",
  "pass-required": "Use 10 or more letters, with at least 1 number, 1 capital letter and 1 lower letter",
  "passport": "Passport",
  "password": "Password",
  "password-format": "Please make a strong password that contains at least 10 characters, one capital letter, one lower letter and one number",
  "payment-plan": "Payment plan",
  "payslip": "Payslip",
  "pdf": "PDF",
  "pending": "Pending",
  "person-type": "Person Type",
  "phone": "Phone",
  "physical-person": "Physical person",
  "position": "Position",
  "postal-code": "Postal code",
  "powerpoint": "Powerpoint",
  "presentation": "Presentation",
  "properties": "Properties",
  "properties-number": "Properties number",
  "property": "Property",
  "property-filter.bathrooms_number": "Bathrooms",
  "property-filter.bedrooms_number": "Bedrooms",
  "property-filter.built_surface": "Built surface",
  "property-filter.city": "City",
  "property-filter.price": "Price",
  "property-filter.property_type": "Property type",
  "property-filter.province": "Province",
  "property-filter.residential_development": "Development",
  "property-filter.toilets_number": "Toilets",
  "property-filter.useful_surface": "Useful surface",
  "property-types": "types",
  "property.bathrooms-number": "Bathrooms",
  "property.bedrooms-number": "Bedrooms",
  "property.block": "Block",
  "property.built-surface": "Built surface",
  "property.buyer": "Buyer",
  "property.buyer-search": "Buyer search",
  "property.cadastral-reference": "Cadastral reference",
  "property.common-zones-surface": "Common zones surface",
  "property.contact": "Contact",
  "property.data": "Property data",
  "property.detailed-spaces": "Detailed spaces",
  "property.doorway": "Doorway",
  "property.estate": "Estate",
  "property.floor": "Floor",
  "property.garages-number": "Garages",
  "property.ladder": "Ladder",
  "property.letter": "Letter",
  "property.name": "Property name",
  "property.orientation": "Orientation",
  "property.price": "Price",
  "property.propertyType": "Property type",
  "property.reference": "Reference",
  "property.rental": "Rental price",
  "property.reserved-date": "Reserved date",
  "property.room.name": "Room name",
  "property.room.select_type": "Select a room type",
  "property.room.surface": "Surface",
  "property.room.type": "Type",
  "property.select_orientation": "Select...",
  "property.select_propertyState": "Select a property state",
  "property.select_propertyType": "Select a property type",
  "property.select_residential-development": "Select a development",
  "property.state": "State",
  "property.storage-rooms-number": "Storage rooms",
  "property.technical-data": "Technical Data ",
  "property.terrace": "Terrace / Garden",
  "property.toilets-number": "Toilets",
  "property.useful-surface": "Useful surface",
  "property.user": "User",
  "proposal.filters": "Filters",
  "proposal.type": "Type",
  "province": "Province",
  "province_id": "Province",
  "publish": "Publish",
  "published": "Published",
  "purchase-agreement": "Purchase agreement",
  "purchaser-type": "Purchaser type",
  "push-notification.action-data.proposal": "Proposal",
  "push-notification.action-type": "Action type",
  "push-notification.action-type.default": "Default",
  "push-notification.action-type.proposal": "Proposal",
  "push-notification.active": "Active",
  "push-notification.archived": "Archived",
  "push-notification.daily": "Daily",
  "push-notification.draft": "Draft",
  "push-notification.end-datetime": "End date",
  "push-notification.frequency": "Frequency",
  "push-notification.hourly": "Hourly",
  "push-notification.message": "Message",
  "push-notification.on-end-visit": "On end visit",
  "push-notification.on-start-visit": "On start visit",
  "push-notification.scheduled": "Scheduled",
  "push-notification.segment": "Segment",
  "push-notification.single-sending": "Single sending",
  "push-notification.start-datetime": "Start date",
  "push-notification.subject": "Subject",
  "push-notification.weekly": "Weekly",
  "quality-specifications": "Quality specifications",
  "raffle": "Raffle",
  "rating": "Rating",
  "receipt": "Receipt",
  "receipt.campaign": "Campaign",
  "receipt.campaign.accumulated-balance": "Accumulated balance",
  "receipt.campaign.accumulative-amount": "Accumulative amount",
  "receipt.create-receipt": "Create receipt",
  "receipt.pending-balance": "Pending balance",
  "receipt.save-message": "The receipt has been saved correctly",
  "receipt.select-campaign": "Select campaign",
  "receipt.select-campaigns": "Select campaign",
  "receipt.stamps": "Stamps",
  "receipt.stamps-number": "Stamps number",
  "receipt.status.not-yet-redeemable": "not yet redeemable",
  "receipt.status.redeemed": "Redeemed",
  "receipt.tickets": "Tickets",
  "receipts": "Receipts",
  "receipts.can-not-generate-message": "The client does not have any redeemable receipt",
  "receipts.redeem-receipt": "Redeem receipts",
  "reference": "Reference",
  "reference-campaign": "Reference campaign",
  "reference-date": "Reference date",
  "refresh": "Refresh",
  "register-date": "Register Date",
  "rent-price": "Rent price",
  "rental": "Rental",
  "required": "{{attr}} is required",
  "reset-password": "Reset password",
  "residence_card": "Residence card",
  "residential-development": "Residential development",
  "residential-development.development": "Development",
  "residential-development.multilanguage-files": "multilanguage files",
  "residential-documentation-sent.deed-warning-text": "After introducing the deed date the offer will be closed. You will not longer able to modify any date",
  "residential-offer-close.rejected-common-zones": "Common zones",
  "residential-offer-close.rejected-delivery-date": "Delivery date",
  "residential-offer-close.rejected-other": "Other",
  "residential-offer-close.rejected-other-reason": "Other reasons that were not in the list",
  "residential-offer-close.rejected-pay-form": "Pay form",
  "residential-offer-close.rejected-price": "Price",
  "residential-offer-close.rejected-project": "Project",
  "residential-offer-close.rejected-reasons": "Rejected reasons",
  "residential-offer.accepted": "Accepted",
  "residential-offer.closing-notes": "Closing notes",
  "residential-offer.closingDate": "Closing date",
  "residential-offer.notes": "Notes",
  "residential-offer.open": "Open",
  "residential-offer.openingDate": "Opening date",
  "residential-offer.pending": "Pending",
  "residential-offer.price": "Price",
  "residential-offer.rejected": "Rejected",
  "residential-offer.select_new_state": "Select new state",
  "residential-offer.sent_info_dossier": "Dossier",
  "residential-offer.sent_info_property_map": "Property map",
  "residential-offer.sent_info_qualities_note": "Qualities note",
  "residential-offer.signed": "Signed",
  "residential-offer.unique": "Unique",
  "residential-offers.price": "Price",
  "residential-owner": "Residential owner",
  "residential-properties.bedrooms": "Bedrooms",
  "residential-properties.location": "Location",
  "residential-properties.price": "Price",
  "residential-properties.property": "Property",
  "residential-properties.rooms": "Rooms",
  "residential-properties.surface": "Surface",
  "residential-properties.type": "Type",
  "residential-property.booking-documentation": "Booking documentation",
  "residential-property.booking-payment": "Booking payment",
  "residential-property.deed": "Deed",
  "residential-property.edit": "Edit property",
  "residential-property.monthly-payment-system": "Monthly payment system",
  "residential-property.new": "New property",
  "residential-property.state.blocked": "Blocked",
  "residential-property.state.free": "Available",
  "residential-property.state.reserved": "Reserved",
  "residential-property.state.sold": "Sold",
  "residential-property.trading": "Trading",
  "residential-proposal.close_offer": "Close offer",
  "residential-request": "Request",
  "residential-room.type.bathroom": "Bathroom",
  "residential-room.type.bedroom": "Bedroom",
  "residential-room.type.dressing-room": "Dressing room",
  "residential-room.type.garage": "Garage",
  "residential-room.type.hall": "Hall",
  "residential-room.type.kitchen": "Kitchen",
  "residential-room.type.laundry-room": "Laundry room",
  "residential-room.type.living-room": "Living room",
  "residential-room.type.lobby": "Lobby",
  "residential-room.type.storage": "Storage",
  "residential-room.type.toilet": "Toilet",
  "residential-rooms-required": "Name and type of each room are required",
  "residential-sale-process.document_sent": "Documentation sent",
  "residential-user-state.accepted": "Accepted",
  "residential-user-state.new": "New",
  "residential-user-state.open": "Open",
  "residential-user-state.rejected": "Rejected",
  "residential-user-state.request": "Request",
  "residential-user-state.signed": "Signed",
  "residential.add_files_button": "Add files",
  "residential.add_files_title": "Add files?",
  "residential.add_link_button": "Add link",
  "residential.add_video_link": "Add video URL",
  "residential.campaign.edit": "Edit campaign",
  "residential.campaign.name": "Name",
  "residential.campaign.new": "New campaign",
  "residential.community_expenses": "Community expenses",
  "residential.development": "Development",
  "residential.development.buildingState": "Building state",
  "residential.development.country-search": "Search by country",
  "residential.development.unique": "Unique",
  "residential.development.name": "Name",
  "residential.development.owner": "Owner",
  "residential.development.province-search": "Search by province",
  "residential.development.reference": "Development reference",
  "residential.development.tu": "T.P.",
  "residential.development.zone": "Zone",
  "residential.development_maps": "Development floor plan",
  "residential.developments": "Developments",
  "residential.documentation": "Documentation",
  "residential.dossiers": "Marketing dossier",
  "residential.end_of_construction": "End of construction",
  "residential.energy_certification.a": "A",
  "residential.energy_certification.b": "B",
  "residential.energy_certification.c": "C",
  "residential.energy_certification.d": "D",
  "residential.energy_certification.e": "E",
  "residential.energy_certification.f": "F",
  "residential.energy_certification.g": "G",
  "residential.how_to_arrive_map": "Plan \"How to arrive\"",
  "residential.images": "Images",
  "residential.in_construction": "In construction",
  "residential.maps": "Floor plans",
  "residential.multimedia": "Files and multimedia",
  "residential.owner.code": "Code",
  "residential.owner.edit": "Edit residential owner",
  "residential.owner.fiscal-name": "Fiscal name",
  "residential.owner.logo": "Logo",
  "residential.owner.name": "Name",
  "residential.owner.new": "New residential owner",
  "residential.owner.vat-number": "VAT number",
  "residential.post_sale": "Post sale",
  "residential.presale": "Presale",
  "residential.properties": "Properties",
  "residential.property": "Property",
  "residential.property.development_documentation": "Development documentation",
  "residential.property.development_multimedia_files": "Development multimedia files",
  "residential.property.reserved-date": "Reserved date",
  "residential.property.reserved-notes": "Notes",
  "residential.property_maps": "Property floor plans",
  "residential.proposal.send-info": "Send info",
  "residential.proposal.send-info.error-sending-message": "An error occurred while sending the information mail. Please try again later",
  "residential.proposal.send-info.error-updating-checks-message": "The proposal hasn't been uploaded correctly",
  "residential.proposal.send-info.success-message": "The information mail has been sent successfully",
  "residential.rooms": "Rooms",
  "residential.sale": "Sale",
  "residential.start_work": "Start of work",
  "residential.zone_services": "Zone services",
  "residential_offers.closing": "Closing offer",
  "residential_offers.documentation-procedure": "Documentation procedure",
  "residential_offers.new": "New offer",
  "residential_property": "Property",
  "residential_proposal": "Proposal",
  "reward": "Reward",
  "sale": "Sale",
  "sale-process.add-request": "Add request",
  "sale-process.closing": "Closing",
  "sale-process.proposal": "Proposal",
  "sale-process.proposals": "Proposals",
  "sale-process.request.date": "Date",
  "sale-process.request.name": "Name",
  "sale-process.request.properties-available": "Available",
  "sale-process.request.properties-blocked": "Blocked",
  "sale-process.requests": "Requests",
  "sales": "sales",
  "same-period-last": "Same period last {{period}}",
  "saturday": "Saturday",
  "saturday-short": "Sat",
  "save": "Save {{modelName}}",
  "scmanager": "SC Manager",
  "search": "Search",
  "second-property": "Second property",
  "segment": "Segment",
  "segment.filters-message-first-part": "Find customers that match",
  "segment.filters-message-second-part": "the following conditions:",
  "segment.total_users_reached": "Total users reached",
  "segment.warning_update_users": "The campaign will be daily sent to new users that match the segment conditions",
  "segments.filters_needed": "At least one of these filters is needed:",
  "segments.info": "Info",
  "segments.save_as": "Save as {{modelName}}",
  "segments.save_changes": "Save changes",
  "segments.save_new": "Save as new {{modelName}}",
  "segments.type_name": "Type the {{modelName}} name",
  "select": "Select {{attr}}",
  "select-building": "Select building",
  "select-center": "Select center",
  "select-channel": "Select channel",
  "select-country": "Select country",
  "select-level": "Select level",
  "select_all": "Select all",
  "select_one_option": "Select an option",
  "sell-price": "Sell price",
  "send": "Send",
  "send-email-template.help.multipleEmails": "Enter multiple e-mail addresses separated by commas",
  "send_economic_proposal": "Send economic proposal",
  "send_email": "Send e-mail",
  "september": "September",
  "september-short": "Sep",
  "service": "Service",
  "services-and-qualities": "Services and qualities",
  "sex": "Gender",
  "shop": "Store",
  "shop-search": "Search store",
  "shopMonth.state.closed": "Closed",
  "shopMonth.state.unclosed": "Open",
  "shopMonth.state.validated": "Validated",
  "shopMonth.state.without_sales": "Without sales",
  "shops": "Stores",
  "shops.not_found": "No stores found",
  "show-children": "Show children",
  "show-details": "Show details",
  "show-development": "Show development",
  "show-price": "Show price",
  "single": "Single",
  "single_family": "Single family",
  "sold": "Sold",
  "soluciona_errores": "Soluciones errores",
  "south": "South",
  "spreadsheet": "Spreadsheet",
  "staff-level-sales": "Sales",
  "staff-level-services": "Services",
  "staff-manager": "Staff managers",
  "staff-member": "Staff member",
  "staff-members.new": "New Staff Member",
  "staff.level.admin": "Admin",
  "staff.level.commerce": "Store",
  "staff.level.crmmanager": "CRM Manager",
  "staff.level.crmoperator": "CRM Operator",
  "staff.level.manager": "Manager",
  "staff.level.owner": "Owner",
  "staff.level.scmanager": "SC Manager",
  "staff.level.storeManager": "Store Manager",
  "staff.level.weboperator": "Web Operator",
  "stamps": "Stamps",
  "stamps.not-enough-stamps": "The client does not have enough receipts to redeem for stamps",
  "stamps.preview-message": "Stamps summary",
  "stars": "stars",
  "start-date": "Start date",
  "state": "State",
  "status": "Status",
  "stop": "Stop",
  "storage-plan": "Storage plans",
  "storage-rooms-number": "Storage rooms number",
  "store": "Store",
  "store-manager": "Store Manager",
  "subway": "Subway",
  "sunday": "Sunday",
  "sunday-short": "Sun",
  "supplier": "Supplier",
  "supplier-contact.filters": "Filters",
  "supplierRating": "Supplier rating",
  "suppliers.filters": "Filters",
  "table-list.not_found": "There are no elements available",
  "technical-data": "Technical data",
  "thecnical-data": "Thechnical data",
  "thumbnail": "Thumbnail",
  "thursday": "Thursday",
  "thursday-short": "Thurs",
  "ticket.campaign": "Campaign",
  "ticket.campaign-name": "Campaign",
  "ticket.code": "Code",
  "ticket.created-at": "Created at",
  "ticket.generate-error-message": "There was a problem when generating the tickets and they haven't beend created. Please, contact with the support team.",
  "ticket.generate-success-message": "The tickets has been generated correctly.",
  "ticket.label": "Rule",
  "ticket.loyalty-card": "Loyalty Card",
  "ticket.pending-balance": "Pending balance",
  "ticket.preview-message": "Tickets summary",
  "ticket.raffle": "Raffle",
  "ticket.rule": "Rule",
  "ticket.tickets-number": "Tickets",
  "tickets.amount": "Amount",
  "tickets.code": "Code",
  "tickets.date": "Date",
  "tickets.not-enough-tickets": "The client does not have enough receipts to redeem for tickets",
  "tickets.resume": "Resume",
  "tickets.salesman": "Salesman",
  "tickets.shop-helper-message": "You can only select stores that belong to the selected campaigns",
  "tickets.shop-search": "Search store",
  "tickets.shop-search.no-shops": "No shops found in the selected campaigns",
  "title.header.configuration": "Configuration",
  "title.header.configuration.dashboard": "Dashboard configuration",
  "title.header.configuration.staff-members": "Staff members",
  "title.header.crm.users": "Customers",
  "title.header.dashboards.show": "Dashboard",
  "title.header.desktop": "Desktop",
  "title.header.emailing.campaigns": "Email campaigns",
  "title.header.residential.campaigns": "Campaigns",
  "title.header.residential.developments": "Developments",
  "title.header.residential.owners": "Owners",
  "title.header.residential.properties": "Properties",
  "title.header.testing": "Test header",
  "token": "Token",
  "total_sales": "Total sales",
  "train": "Train",
  "tuesday": "Tuesday",
  "tuesday-short": "Tues",
  "type": "Type",
  "unit-type": "Unit type",
  "unpublish": "Unpublish",
  "upcoming-commercialization": "Upcoming commercialisation",
  "update-password.server-problem": "There was a problem with the server. Please, contact with support team",
  "upload": "Upload",
  "url": "URL",
  "useful-surface": "Useful sur.",
  "user": "User",
  "user-error": "Something is wrong. Please check the following errors: ",
  "user.actions": "Actions",
  "user.address": "Address",
  "user.app-notifications-check": "I wish to receive app notifications",
  "user.bday-days": "Birthday (days)",
  "user.birthdate": "Birthdate",
  "user.campaigns": "Campaigns",
  "user.can-access-bonus-request": "Can access bonus request",
  "user.channels": "Channels",
  "user.children_number": "Children number",
  "user.city": "City",
  "user.code": "Customer code",
  "user.company": "Company",
  "user.countries": "Countries",
  "user.cp": "Postal code",
  "user.created_at": "Registration date{{mandatory}}",
  "user.dni": "Vat number",
  "user.document_type": "Document type",
  "user.electronic_communications_phrase": "I wish to receive electronic commercial communications",
  "user.email": "Email",
  "user.gdpr_phrase": "I agree with the GDPR",
  "user.general_data": "General data",
  "user.last_name": "Last name",
  "user.manual-campaigns": "Manual campaings",
  "user.marital_status": "Marital status",
  "user.marketing_data": "Marketing",
  "user.name": "Name",
  "user.nationality": "Nationality",
  "user.nationality-search": "Search nationality",
  "user.no-assigned-campaign-message": "No assigned segmented campaigns",
  "user.no-assigned-segment-message": "No assigned segements",
  "user.notes": "Notes",
  "user.other_data": "Other data",
  "user.partners_electronic_communications_phrase": "I wish to receive electronic commercial communications from thirds",
  "user.person_type": "Person type",
  "user.phone": "Phone",
  "user.province": "Province",
  "user.province-search": "Search province",
  "user.purchaser-type": "Purchaser type",
  "user.reference-building": "Reference building",
  "user.reference-commerce": "Reference store",
  "user.salesman": "Salesman",
  "user.secondary_phone": "Secondary phone",
  "user.segment": "Segment",
  "user.segmented-campaigns": "Automatically assigned segmented campaigns",
  "user.select_document_type": "Select document type",
  "user.select_marital_status": "Select marital status",
  "user.select_sex": "Select gender",
  "user.sex": "Gender",
  "user.user-segments": "User segments",
  "user.user-types": "User types",
  "user_types": "User types",
  "users.amount": "Amount",
  "users.campaigns": "Campaigns",
  "users.code": "Code",
  "users.commerce": "Store",
  "users.date": "Date",
  "users.edit": "Edit customer",
  "users.general_data": "General data",
  "users.loyalty": "Loyalty",
  "users.new": "New customer",
  "users.not-has-email-warning-message": "It is recommended to add the user email",
  "users.receipts": "Receipts",
  "users.sale_process": "Sale process",
  "users.warning": "Warning",
  "usertypes": "Usertypes",
  "value": "Value",
  "vat-number": "Vat number",
  "video": "Video",
  "visibility": "Visibility",
  "visits": "Visits",
  "visits.recurrence": "Recurrence",
  "want_duplicate": "Do you really want to duplicate the {{modelName}} {{name}}?",
  "want_remove": "Do you really want to delete this {{modelName}}?",
  "want_save_without_gdpr_or_electronic_communications": "The terms and conditions or the electronic communications checks aren’t checked, do you wish to continue?",
  "want_stop": "Do you really want to stop the {{modelName}} {{name}}?",
  "warning": "Warning",
  "warning-residential-change-text": "The changes that you're going to do can affect external services. Are you sure you want to proceed?",
  "waterkmark": "Watermark",
  "web": "Web",
  "web-image": "Web Image",
  "web-operator": "Web Operator",
  "wednesday": "Wednesday",
  "wednesday-short": "Wed",
  "week": "Week",
  "weekday.friday": "Friday",
  "weekday.monday": "Monday",
  "weekday.saturday": "Saturday",
  "weekday.sunday": "Sunday",
  "weekday.thursday": "Thursday",
  "weekday.tuesday": "Tuesday",
  "weekday.wednesday": "Wednesday",
  "west": "West",
  "wizard.next_step": "Next",
  "wizard.prev_step": "Previous",
  "woman": "Woman",
  "yardi.continue_export": "Continue export",
  "yardi.shop_property_error": "The store needs a property linked.",
  "yardi.tenant_code_error": "The store needs an specific tenant code.",
  "yardi.warn_errors_exporting": "There are errors in the export. Please, review them before continue.",
  "year": "Year",
  "yen": "Yen ¥",
  "yes": "Yes",
  "youtube.url": "YouTube URL",
  "zone-1": "Zone 1",
  "zone-2": "Zone 2",
  "zone-3": "Zone 3",
  "zone-4": "Zone 4",
  "zone-services": "Zone services",
  "children_age": "Children age",
  "children_number": "Children number",
  "select_month": "Select a month",
  "select_year": "Select a year",
  "legal_person": "Legal person",
  "residential.multimedia_files": "Multimedia files",
  "segments.save_new_segment": "Save as new segment",
  "save_segment": "Save segment",
  "segments.save_as_segment": "Save as segment",
  "map-description": "Map description",
  "costa": "Coast",
  "available-premise": "Available premise",
  "user.age": "Age",
  "users.age.under-35": "Under 35",
  "users.age.35-45": "35-45",
  "users.age.50-65": "50-65",
  "users.age.over-65": "Over 65",
  "user.select_age": "Select age",
  "users.nationality.spanish": "Spanish",
  "users.nationality.foreigner": "Foreigner",
  "user.select_nationality": "Select nationality",
  "user.civil_status": "Civil status",
  "user.select_civil_status": "Select civil status",
  "users.civil-status.single": "Single",
  "users.civil-status.married": "Married",
  "users.civil-status.divorced": "Divorced",
  "users.civil-status.widower": "Widower",
  "user.foreign_nationality": "Foreign nationality",
  "user.select_foreign_nationality": "Select foreign nationality",
  "users.foreign-nationality.french": "French",
  "users.foreign-nationality.portuguese": "Portuguese",
  "users.foreign-nationality.italian": "Italian",
  "users.foreign-nationality.united-kingdom": "United Kingdom",
  "users.foreign-nationality.german": "German",
  "users.foreign-nationality.mexican": "Mexican",
  "users.foreign-nationality.brazilian": "Brazilian",
  "user.lead_origin": "Lead origin",
  "user.select_lead_origin": "Select lead origin",
  "users.lead-origin.web_cbre": "Web CBRE",
  "users.lead-origin.landing_origami": "Landing promotion",
  "users.lead-origin.valla": "Valla",
  "users.lead-origin.referenced": "Referenced",
  "users.lead-origin.event": "Event",
  "users.lead-origin.social_network": "Social network",
  "users.lead-origin.emailing": "Emailing",
  "users.lead-origin.developer_website": "Developer website",
  "users.lead-origin.press": "Press",
  "users.lead-origin.selling_point": "Selling point",
  "users.lead-origin.idealista": "Idealista",
  "user.typology": "Typology",
  "user.select_typology": "Select typology",
  "users.typology.multi-family": "Multi-family",
  "users.typology.single-family": "Multi-family",
  "user.reason_for_purchase": "Reason for purchase",
  "user.select_reason_for_purchase": "Select reason for purchase",
  "users.reason-for-purchase.first-home": "First home",
  "users.reason-for-purchase.second-home": "Second home",
  "users.reason-for-purchase.investment": "Investment",
  "users.reason-for-purchase.replacement": "Replacement",
  "user.state": "State",
  "user.select_state": "Select state",
  "users.state.monitoring": "Monitoring",
  "users.state.discarded": "Discarded",
  "users.state.pre-registration": "Pre-registration",
  "users.state.incorporation": "Incorporation",
  "users.state.writing": "Writing",
  "user.visit": "Visit",
  "user.source_of_funds": "Source of funds",
  "user.select_source_of_funds": "Select source of funds",
  "user.reason_for_discard": "Reason for discard",
  "user.select_reason_for_discard": "Select reason for discard",
  "users.reason-for-discard.location": "Location",
  "users.reason-for-discard.price": "Price",
  "users.reason-for-discard.surface": "Surface",
  "users.reason-for-discard.deadline": "Deadline",
  "users.reason-for-discard.buy_competition": "Buy competition",
  "users.reason-for-discard.other": "Other",
  "users.source-of-funds.own": "Own",
  "users.source-of-funds.loan": "Loan",
  "user.number_of_rooms": "Number of rooms",
  "user.select_number_of_rooms": "Select number of rooms",
  "users.rooms.one": "1",
  "users.rooms.two": "2",
  "users.rooms.tree": "3",
  "users.rooms.four": "4",
  "users.rooms.five": "5",
  "property.add_residential-development": "Add development",
  "crm.users.created-at": "Registration date",
}
;