export default {
    "Channel": "Canal",
    "KYC": "KYC",
    "Image": "Imagen",
    "Update": "Actualizar",
    "accept": "Aceptar",
    "access_data": "Datos de acceso",
    "access_date": "Fecha de acceso",
    "access_head": "Contacto principal",
    "achievement": "Logro",
    "actions.action_type": "Tipo de acci\u00f3n",
    "action.check_as_done": "Marcar acci\u00f3n como completada",
    "actions.add": "A\u00f1adir acci\u00f3n",
    "actions.call": "Llamada",
    "actions.date": "Fecha",
    "actions.datetime": "Fecha",
    "actions.edit": "Editar acci\u00f3n",
    "actions.email": "Email",
    "actions.form": "Formulario",
    "actions.new": "Nueva acci\u00f3n",
    "actions.notes": "Notas",
    "actions.rejection": "Rechazo",
    "actions.rejection_title": "Oferta rechazada",
    "actions.reservation": "Reserva",
    "actions.reservation_title": "Inmueble reservado",
    "actions.select_action_type": "Selecciona tipo de acci\u00f3n",
    "actions.sent_marketing_info": "Informaci\u00f3n de marketing enviada",
    "actions.sent_marketing_info_title": "Informaci\u00f3n enviada",
    "actions.signed": "Firmado",
    "actions.signed_title": "Inmueble firmado",
    "actions.staff": "Usuario",
    "actions.title": "T\u00edtulo",
    "actions.visit": "Visita",
    "active.users": "Usuarios activos",
    "add": "A\u00f1adir",
    "add-file": "A\u00f1adir fichero",
    "add-files": "A\u00f1adir uno o m\u00e1s ficheros",
    "address": "Direcci\u00f3n",
    "admin": "Admin",
    "advanced_search": "B\u00fasqueda avanzada",
    "age": "Edad",
    "alert-type": "Tipo de alerta",
    "all": "Todas",
    "amount": "Importe",
    "analytics.no-dashboard": "No hay elementos disponibles",
    "and": "y",
    "answer": "Respuesta",
    "any-of": "cualquiera de",
    "api-error": "Algo est\u00e1 fallando y no es culpa tuya. Ponte en contacto con soporte e indica la siguiente informaci\u00f3n",
    "app-image": "Imagen de la App",
    "app-notifications": "Notificaciones app",
    "apply_filters": "Aplicar filtros",
    "april": "Abril",
    "april-short": "Abr",
    "are_you_sure": "\u00bfEst\u00e1s seguro?",
    "attach_files": "Adjuntar archivos",
    "august": "Agosto",
    "august-short": "Ago",
    "average": "Media",
    "average.visit.time": "Tiempo de permanencia",
    "back": "Volver",
    "bank-account-number": "IBAN",
    "bdate": "Cumplea\u00f1os",
    "between": "entre",
    "bime": "BIME",
    "bime_dashboard.not_found": "Panel Bime no encontrado",
    "bime_dashboard.type_find": "Escribe para encontrar paneles Bime",
    "bonus-request": "Solicitud de bonificaci\u00f3n",
    "bonus-shop.created-at": "Fecha",
    "bonusRequest.bonus-percentage": "Solicitud de descuento de alquiler (%)",
    "bonusRequest.bonus-shop": "Marca",
    "bonusRequest.bonus-period": "Duraci\u00f3n del descuento de alquiler (meses)",
    "bonusRequest.confirm-bonus-request": "Confirmo que la solicitud de bonificaci\u00f3n es v\u00e1lida.",
    "bonusRequest.costs-data": "Datos de costes",
    "bonusRequest.depreciation": "Depreciaci\u00f3n",
    "bonusRequest.edit": "Editar",
    "bonusRequest.employee-cost": "Costes de empleado por a\u00f1o",
    "bonusRequest.employee-hours": "Horas de empleados",
    "bonusRequest.employee-hours-year": "Horas de empleado por a\u00f1o",
    "bonusRequest.exercise": "Per\u00edodo del ejercicio contable",
    "bonusRequest.franchisee": "Franquiciado",
    "bonusRequest.franchisee-expenses": "Gastos de franquiciado",
    "bonusRequest.month": "Meses",
    "bonusRequest.month-label": "Periodo desde enero a...",
    "bonusRequest.new": "Nuevo",
    "bonusRequest.other-expenses": "Otros gastos",
    "bonusRequest.sale-cost": "Coste de venta",
    "bonusRequest.sales": "Ventas",
    "bonusRequest.sales-tooltip": "Ejercicio de ventas (e.g. 2019)",
    "bonusRequest.select-exercise": "Selecciona a\u00f1o",
    "bonusRequest.select-month": "Selecciona mes",
    "bonusRequest.shops": "Marca",
    "bonusRequest.shops-search": "Busca una marca",
    "bonusRequest.supplies": "Suministros",
    "bonusRequest.surface": "Superficie(m2)",
    "bonusRequests.exercise": "Periodo del ejercicio contable",
    "bookable": "Reservable",
    "booking-contract": "Contrato reserva",
    "booking-price": "Precio Reserva",
    "both": "Ambos",
    "boy": "Ni\u00f1o",
    "breadcrumbs.main": "Principal",
    "building-state": "Estado Construcci\u00f3n",
    "built-surface": "Superficie Construida",
    "bus": "Bus",
    "cadastral-reference": "Ref. Catastral",
    "calendar": "Calendario",
    "calibrator": "Calibrator",
    "call-to-action": "Call to action",
    "campaign": "Campa\u00f1a",
    "campaign-action": "Acci\u00f3n",
    "campaign-action.new": "Nueva acci\u00f3n de campa\u00f1a",
    "campaign-goal.kpi": "Kpi",
    "campaign-goal.new": "Objetivo de nueva campa\u00f1a",
    "campaign-goal.value": "Valor",
    "campaign-report.all-shops": "Todas las tiendas",
    "campaign-report.campaign-actions": "Acciones de campa\u00f1a",
    "campaign-report.campaign-cost": "Coste de la campa\u00f1a",
    "campaign-report.campaign-goals": "Objetivos de la campa\u00f1a",
    "campaign-report.campaign-kpi": "Kpi de Campa\u00f1a",
    "campaign-report.campaign-type": "Tipo de campa\u00f1a",
    "campaign-report.description": "Descripci\u00f3n",
    "campaign-report.estimated-cost": "Coste estimado",
    "campaign-report.final-cost": "Coste final",
    "campaign-report.final-cost-in-estimated": "Dentro del presupuesto",
    "campaign-report.final-cost-over-estimated": "Por encima del presupuesto",
    "campaign-report.more-shops": "+ {{shops}} tiendas",
    "campaign-report.notes": "Informar observaciones",
    "campaign-report.rating": "Calificaci\u00f3n de campa\u00f1a",
    "campaign-report.reference-period": "Per\u00edodo de referencia",
    "campaign-report.select-visible-kpis": "Seleccionar KPI visibles",
    "campaign-report.shops": "Tiendas",
    "campaign-report.suppliers-rating": "Calificaci\u00f3n de proveedores",
    "campaign-rule.label": "Etiqueta",
    "campaign-rule.new": "Nueva regla de campa\u00f1a",
    "campaign-rule.ticket-amount": "Importe de ticket",
    "campaign.close": "Cerrar campa\u00f1a",
    "campaign.date-range": "Rango de fechas",
    "campaign.filters": "Filtros",
    "campaign.edit": "Editar campa\u00f1a",
    "campaign.goal": "Objetivo",
    "campaign.new": "Nueva Campa\u00f1a",
    "campaign.reference": "Referencia",
    "campaign.reference-type": "Tipo de referencia",
    "campaign.segment.list": "Lista de segmentos",
    "campaign.segments": "Segmentos",
    "campaign.status.closed": "Cerrado",
    "campaign.status.active": "Activo",
    "campaign.status.finished": "Finalizado",
    "campaign.status.not-redeemable": "No canjeable",
    "campaign.status.planned": "Planificado",
    "campaign.status.redeemable": "Canjeable",
    "campaign_id": "Campa\u00f1a",
    "campaigns": "Campa\u00f1as",
    "campaigns.new": "Nueva campa\u00f1a",
    "campaigntype": "Tipo de campa\u00f1a",
    "campaigntype.edit": "Editar tipo de campa\u00f1a",
    "campaigntype.new": "Nuevo tipo de campa\u00f1a",
    "cancel": "Cancelar",
    "capacity": "Capacidad",
    "cars": "Coches",
    "categories": "Categor\u00edas",
    "category": "Categor\u00eda",
    "cbre-contact": "Contacto CBRE",
    "center": "Centro",
    "center-id": "ID del centro",
    "center.not_found": "No se han encontrado centros",
    "center.select_one": "Selecciona un centro",
    "centers": "Centros",
    "centers-required": "Centers is required",
    "change-password.confirm-password": "Confirma password",
    "change-password.new-password": "Nueva password",
    "change-password.old-password": "Password Actual",
    "channel-required": "El canal es obligatorio",
    "change-password.title": "Cambio password",
    "channel_id": "Canal",
    "channels": "Canales",
    "channels.app": "App",
    "channels.other": "Otro",
    "channels.play-center": "Ludoteca",
    "channels.social-wifi": "Social Wifi",
    "channels.web": "Web",
    "check-in": "Entrada",
    "child": "Hijo\/a",
    "child.bdate": "Fecha de nacimiento",
    "child.check-out": "Salida",
    "child.checkin-success-message": "Ni\u00f1o registrado correctamente",
    "child.checking-out": "Verificar",
    "child.checkn": "Entrada",
    "child.checkout-success-message": "Se ha registrado la salida del ni\u00f1o correctamente",
    "child.edit": "Editar",
    "child.last-name": "Apellidos",
    "child.name": "Nombre",
    "child.new": "Nuevo",
    "child.not-dni-error-message": "El usuario no tiene DNI. Por favor, vaya al perfil de usuario para configurarlo",
    "child.notes": "Notas",
    "child.register-entry": "Registro de entrada",
    "child.relationship": "Parentesco",
    "child.select-relationship": "Seleccione parentesco",
    "child.select-sex": "Selecciona g\u00e9nero",
    "child.sex": "G\u00e9nero",
    "children": "Hijos",
    "children-count": "{{count}} hijo",
    "children-count_plural": "{{count}} hijos",
    "children-number": "N\u00famero de ni\u00f1os",
    "children_age": "Edad de los hijos",
    "children_number": "N\u00famero de ni\u00f1os",
    "cif": "CIF",
    "cinemas": "Cines",
    "city": "Ciudad",
    "clear": "Limpiar",
    "clear_filters": "Borrar filtros",
    "close": "Cerrar",
    "close-month.button.confirm": "Confirmar cierre del mes",
    "close-month.button.reject": "Rechazar cierre del mes",
    "close-month.message.pending-request": "Hay una solicitud pendiente este mes de cierre de mes",
    "close_month": "Cerrar mes",
    "closed": "Cerrado",
    "cms.commerce-access-information.sent-error": "Se produjo un error al intentar enviar la informaci\u00f3n solicitada. Por favor int\u00e9ntalo de nuevo m\u00e1s tarde.",
    "closed_month_pending": "Pendiente",
    "cms.commerce-access-information.sent-success": "La informaci\u00f3n de acceso solicitada se ha enviado correctamente a su correo electr\u00f3nico, puede tardar unos minutos en llegar a su bandeja de entrada",
    "cms.commerce-stay-alert.correction": "Correcci\u00f3n",
    "cms.commerce-stay-alert.stays-full": "Permanece lleno",
    "cms.commerce.disabled-sales": "Ventas desactivadas",
    "cms.commerce.extra-attributes": "Atributos extra",
    "cms.commerce.general-data": "Datos Generales",
    "cms.commerce.hide-commerce": "Ocultar tienda",
    "cms.commerce.physical-store": "Tienda f\u00edsica",
    "cms.commerces.qr": "Acceso QR",
    "cms.commerces.qr-error": "Se produjo un error al intentar descargar la informaci\u00f3n solicitada. Por favor int\u00e9ntelo de nuevo  m\u00e1s tarde.",
    "cms.proposal.draft": "Borrador",
    "cms.proposal.active": "Activo",
    "cms.proposal.expired": "Expirado",
    "cms.proposal.extra-attributes": "Atributos extra",
    "cms.proposal.general-data": "Datos generales",
    "cms.proposal.pending": "Pendiente",
    "cms.proposal.scheduled": "Programada",
    "cms.proposals.expire-error": "Error al expirar propuesta",
    "cms.proposals.expire-success": "La propuesta ha expirado con \u00e9xito",
    "cms.proposals.proposal-duplicated": "Propuesta duplicada con \u00e9xito",
    "cms.proposals.proposal-duplicated-error": "Error al duplicar propuesta",
    "cms.proposals.publish-error": "Error al publicar propuesta",
    "cms.proposals.publish-success": "La propuesta ha sido publicada con \u00e9xito",
    "cms.proposals.reject-publication-error": "Error al rechazar la publicaci\u00f3n de la propuesta",
    "cms.proposals.reject-publication-success": "La propuesta ha sido publicada con \u00e9xito",
    "cms.proposals.request-error": "Error al solicitar la publicaci\u00f3n de la propuesta",
    "cms.proposals.request-success": "Se ha solicitado que la propuesta se publique con \u00e9xito",
    "cms.proposals.unpublish-error": "Error al anular la propuesta",
    "cms.proposals.unpublish-success": "La propuesta ha sido cancelada con \u00e9xito",
    "cms.push-notification.general-data": "Datos generales",
    "cms.push-notification.want-publish": "\u00bfQuieres publicar la notificaci\u00f3n push?",
    "comments": "Comentarios",
    "commerce": "Tienda",
    "commerce-stay-alert": "Alerta de estancia en tienda",
    "commerce-stay-alert.alert-date": "Fecha de alerta",
    "commerce-stay-alert.alert-type": "Tipo de alerta",
    "commerce-stay-alert.filters": "Filtros",
    "commerce.capacity-data": "Datos de capacidad",
    "commerce.categories": "Categorias",
    "commerce.edit": "Editar comercio",
    "commerce.extra.address": "Direcci\u00f3n de los atributos extra",
    "commerce.extra.image": "Imagen de los atributos extra",
    "commerce.extra.text": "Texto de atributo extra",
    "commerce.extra.url": "URL de los atributos extra",
    "commerce.general-data": "Datos generales",
    "commerce.html": "HTML de los atributos extra",
    "commerce.new": "Nuevo comercio",
    "commerce.personalized-target": "P\u00fablico objetivo",
    "commerce.store-data": "Datos de la tienda",
    "commerce.whatsapp-message": "Mensaje de whatsapp",
    "commerce.whatsapp-phone": "M\u00f3vil whatsApp",
    "commerce.whatsapp-phone-format": "El numero de m\u00f3vil para WhatsApp debe tener el c\u00f3digo de \u00e1rea antes del n\u00famero. Ejem.+346xxxxxxxx",
    "commerce.yardi-code": "C\u00f3digo Yardi",
    "commerces": "Tiendas",
    "commerces.new": "Nuevo comercio",
    "commercialization": "Comercializaci\u00f3n",
    "commercialization-start": "Inicio de comercializaci\u00f3n",
    "common-zones-surface": "Superficie Zona",
    "communication.label.to_roles": "A",
    "communication.message": "Mensaje",
    "communication.new": "Nueva comunicaci\u00f3n",
    "communication.reject-close-month.subject": "{{shopName}} cierre {{month}} rechazado",
    "communication.roles.commerce": "Tienda",
    "communication.roles.management": "Gesti\u00f3n",
    "communication.roles.services": "Servicios",
    "communication.state.accepted": "Aceptado",
    "communication.state.pending": "Pendiente",
    "communication.state.rejected": "Rechazado",
    "communication.state.solved": "Resuelto",
    "communication.subject": "Asunto",
    "communication.subtype": "Subtipo",
    "communication.to_commerces": "A tiendas",
    "communication.type": "Tipo",
    "communication.type.access": "Acceso",
    "communication.type.issues": "Incidencias",
    "communication.type.marketing": "Marketing",
    "communication.type.news": "Noticias",
    "communication.type.tabs.inbox": "Bandeja de entrada",
    "communication.type.tabs.sent": "Enviadas",
    "communications.creator": "Creador",
    "communications.datetime": "Fecha",
    "communications.state": "Estado",
    "communications.subject": "Asunto",
    "communications.type": "Tipo",
    "community-expenses": "Gastos comunidad",
    "companion.add": "Agregar acompa\u00f1ante",
    "companion.vat_number": "D.N.I.",
    "companion_name": "Nombre del acompa\u00f1ante",
    "companions": "Acompa\u00f1antes",
    "company": "Empresa",
    "concepts.airplane_tickets": "Tickets de avi\u00f3n",
    "concepts.bar": "Bar",
    "concepts.cancellations": "Cancelaciones",
    "concepts.cinema_tickets": "Facturas cine",
    "concepts.coffee": "Caf\u00e9",
    "concepts.drones": "Drones",
    "concepts.equipment": "Equipamiento",
    "concepts.fees_delivery": "Tarifa de entrega",
    "concepts.free_devices": "Dispositivos libres",
    "concepts.game": "Game",
    "concepts.general": "General",
    "concepts.general_variable": "Renta Variable de Merchandise general",
    "concepts.gm1": "General Merchandise 1",
    "concepts.gm2": "General Merchandise 2",
    "concepts.magazines": "Peri\u00f3dicos y revistas",
    "concepts.online_sales": "Ventas online",
    "concepts.phonecard": "Tarjeta telef\u00f3nica",
    "concepts.prescription": "Prescripci\u00f3n",
    "concepts.pub": "Pub",
    "concepts.radio-modelism": "Radio modelismo",
    "concepts.receipts": "Facturas",
    "concepts.rent_car_services": "Servicios de alquiler de coches",
    "concepts.school_mat": "Material escolar",
    "concepts.stamps_and_values": "sellos y valores",
    "concepts.telecommunications": "Servicios de telecomunicaciones",
    "concepts.tobacco": "Tabaco",
    "concepts.tobacco_game": "Tabaco y juegos",
    "concepts.trans_currency": "Transacciones monetarias",
    "concepts.uploads_of_credit": "Cargas de cr\u00e9dito",
    "configuration.staff-member.notification": "Quiero recibir comunicaciones de intranet por correo electr\u00f3nico",
    "confirm": "Confirmar",
    "confirm-modal.cancel": "Cancelar",
    "confirm-modal.ok": "Aceptar",
    "confirm-password": "Confirmar password",
    "contactable": "Contactable",
    "contains": "contiene",
    "contract-end-date": "Fecha de cierre de contrato",
    "contract-start-date": "Fecha de inicio de contrato",
    "countries": "Paises",
    "country": "Country",
    "country-required": "Country is required",
    "couple": "Con pareja",
    "coupon-used": "Cup\u00f3n usado",
    "cp": "C\u00f3digo postal",
    "create": "Crear",
    "create-new": "Crear",
    "created_at": "Fecha de registro",
    "crm-manager": "CRM Manager",
    "crm-operator": "CRM Operator",
    "crm.campaign.active-crm": "Activo en CRM",
    "crm.campaign.attach-image": "Adjuntar imagen (png, jpeg, gif or jpg)",
    "crm.campaign.generic-reward": "Retribuci\u00f3n gen\u00e9rica",
    "crm.campaign.loyalty-campaign": "Campa\u00f1a de tarjeta de fidelidad",
    "crm.campaign.loyalty-campaign-info-message": "Seleccionando una campa\u00f1a de tarjeta de fidelidad generar\u00e1 una recompensa cuando se logre la tarjeta de fidelidad",
    "crm.campaign.loyalty.amount": "Importe",
    "crm.campaign.loyalty.general_data": "Datos generales",
    "crm.campaign.loyalty.receipts-redemption-days": "D\u00edas de recibos redimidos",
    "crm.campaign.loyalty.welcome-stamps": "Sellos de bienvenida",
    "crm.campaign.raffle.block-raffle-info": "No podr\u00e1 modificar la configuraci\u00f3n del sorteo una vez que se haya generado la primera factura. Tenga esto en cuenta al guardar y lanzar esta campa\u00f1a.",
    "crm.campaign.raffle.loyalty-code": "C\u00f3digo de fidelizaci\u00f3n",
    "crm.campaign.raffle.rules": "Excepciones del sorteo",
    "crm.campaign.reward.other-options": "Otras opciones",
    "crm.campaign.reward.valid-days": "D\u00edas v\u00e1lidos",
    "crm.campaign.search-campaign": "Buscar campa\u00f1a",
    "crm.campaign.select-campaign": "Selecciona campa\u00f1a",
    "crm.campaign.share-campaign": "Compartir campa\u00f1a",
    "crm.campaign.submit-image": "Enviar imagen",
    "crm.campaign.without-remains": "Sin remanente",
    "crm.campaigns.loyalty": "Fidelizaci\u00f3n",
    "crm.children.add": "A\u00f1adir ni\u00f1os",
    "crm.children.bdate": "Fecha de nacimiento",
    "crm.children.gender": "G\u00e9nero",
    "crm.children.name": "Nombre",
    "crm.filter.type_find": "Escribe para encontrar filtros",
    "crm.filters-section.crm": "CRM",
    "crm.filters-section.demographic": "Demogr\u00e1fico",
    "crm.filters-section.loyalty": "Fidelizaci\u00f3n",
    "crm.filters-section.requests": "Peticiones",
    "crm.filters-section.sales": "Ventas",
    "crm.segments": "Segmentos",
    "crm.user.remove-email": "Enviar un correo electr\u00f3nico a un contacto notificando que se ha eliminado de la base",
    "crm.users.bdate-month-day": "Cumplea\u00f1os(rango)",
    "crm.users.bdate-today": "Fecha de nacimiento (hoy)",
    "crm.users.children-bdate-month-day": "Cumplea\u00f1os de ni\u00f1os (rango)",
    "crm.users.children-list": "Lista de ni\u00f1os",
    "crm.users.code": "C\u00f3digo",
    "crm.users.email": "Email",
    "crm.users.go-to-users-list": "Ir a usuarios",
    "crm.users.last-action": "\u00daltima acci\u00f3n",
    "crm.users.last-action-datetime": "Fecha \u00faltima acci\u00f3n",
    "crm.users.last_name": "Apellido",
    "crm.users.name": "Nombre",
    "crm.users.not-email": "No tiene email",
    "crm.users.phone": "Tel\u00e9fono",
    "crm.users.state": "Estado",
    "crm.users.user-saved": "El usuario ha sido guardado con \u00e9xito",
    "crm.users.vat-number": "DNI",
    "csv": "CSV",
    "currency": "Divisa",
    "current-pass-required": "Contrase\u00f1a actual obligator\u00eda",
    "current-value": "Valor actual",
    "currently-in-playcenter": "Actualmente en la ludoteca",
    "dashboard": "Panel",
    "dashboard-cms": "Panel cms",
    "dashboard-crm": "Panel crm",
    "dashboard-list.dashboards.not-found": "No se encontraron paneles",
    "dashboard-list.title.active-graphs": "Gr\u00e1ficos activos",
    "dashboard-panel": "Panel dashboard",
    "dashboard.category.general": "General",
    "dashboard.category.home": "Home",
    "dashboard.category.not_found": "No se encontraron categor\u00edas",
    "dashboard.category.traffic": "Tr\u00e1fico",
    "dashboard.error.not-found-bime": "No se encontr\u00f3  panel Bime",
    "dashboard.index.no-home-dashboard": "Bienvenido a Neuromobile Back Office",
    "dashboards": "Dashboards",
    "dashboards.0-17": "< 18",
    "dashboards.1": "1",
    "dashboards.18-24": "18-24",
    "dashboards.2": "2",
    "dashboards.25-34": "25-34",
    "dashboards.3": "3",
    "dashboards.35-44": "35-44",
    "dashboards.4": "4",
    "dashboards.45-99": "> 45",
    "dashboards.active-users": "Usuarios activos",
    "dashboards.active-users-chart": "Usuarios activos vs % usuarios activos (mes)",
    "dashboards.active-vs-registered-users-chart": "Usuarios Activos vs Usuarios Registrados",
    "dashboards.age-range": "Rango de edad",
    "dashboards.app-users": "Usuarios App",
    "dashboards.channels.mail-notifications": "Usuarios registrados con notificaciones de correo activas",
    "dashboards.channels.verified-mail": "Usuarios registrados con correo verificado",
    "dashboards.couple": "Pareja",
    "dashboards.dwell-chart": "Tiempo de permanencia vs Competencia (mes)",
    "dashboards.dwell-time": "Tiempo de permanencia",
    "dashboards.email": "Email v\u00e1lido",
    "dashboards.email-notifications": "Notificaciones email",
    "dashboards.family": "Familia",
    "dashboards.female": "Mujer",
    "dashboards.frequency-chart": "Frecuencia de visita frente a Competencia (mes)",
    "dashboards.gender": "G\u00e9nero",
    "dashboards.influence-zone": "\u00c1rea de influencia",
    "dashboards.influence-zones": "Zonas de influencia",
    "dashboards.mail-notif-consent": "Notificaciones email",
    "dashboards.mail-partner-consent": "Notificaciones de terceros",
    "dashboards.male": "Hombre",
    "dashboards.marital-status": "Estado Civil",
    "dashboards.men": "Hombre",
    "dashboards.new-app-users": "Usuarios registrados App",
    "dashboards.new-registered-users": "Usuarios registrados",
    "dashboards.none": "Ninguno",
    "dashboards.other": "Otros",
    "dashboards.other-users": "Otros usuarios",
    "dashboards.per-active-users": "Porcentaje de usuarios activos",
    "dashboards.phone": "Tel\u00e9fono",
    "dashboards.play-center-users": "Usuarios de Ludoteca",
    "dashboards.quality-data-chart": "Calidad datos de usuario",
    "dashboards.recurrence": "Frecuencia de visita",
    "dashboards.registered-app-users": "Usuarios registrados App",
    "dashboards.registered-total-users-chart": "Registros en la App frente a Registros totales (suma)",
    "dashboards.registered-users": "Total usuarios registrados",
    "dashboards.registered-users-chart": "Registros en la App frente a Registros totales (mes)",
    "dashboards.registered-users-total": "Total Usuarios Registrados",
    "dashboards.registers-per-day": "Registros mensuales por d\u00eda",
    "dashboards.registration-rate": "Tasa de registro",
    "dashboards.registration-rate-chart": "Tasa de registro (mes)",
    "dashboards.registration-vs-consent-chart": "Registro vs Consentimiento",
    "dashboards.rsocial-users": "Usuarios Social Wifi",
    "dashboards.single": "Soltero\/a",
    "dashboards.thirt-party-notifications": "Notificaciones de terceros",
    "dashboards.total-app-users": "Total usuarios registrados en la App",
    "dashboards.total-registered-users": "Total usuarios registrados",
    "dashboards.user-profiles-age-chart": "Edad",
    "dashboards.user-profiles-catchment-area-chart": "\u00c1rea de captaci\u00f3n",
    "dashboards.user-profiles-family-chart": "Familia",
    "dashboards.user-profiles-gender-chart": "G\u00e9nero",
    "dashboards.vat-number": "DNI",
    "dashboards.visits": "Visitas",
    "dashboards.visits-competition-chart": "Visitas frente a competencia (mes)",
    "dashboards.visits-users-chart": "Visitas frente a usuarios activos",
    "dashboards.web-users": "Usuarios web",
    "dashboards.weekly.database-increase": "Nuevos usuarios registrados por canal",
    "dashboards.weekly.downloads": "Descargas",
    "dashboards.weekly.downloads-chart": "Descargas (semana)",
    "dashboards.weekly.dwell-time-chart": "Tiempo de permanencia (semana)",
    "dashboards.weekly.last-eight-weeks-chart": "Usuarios registrados desde hace 8 semanas por canal",
    "dashboards.weekly.registered-users-channel": "Usuarios registrados por canal",
    "dashboards.weekly.total-registers": "Total usuarios registrados",
    "dashboards.weekly.user-profile-selected-week": "Perfil de usuario de la semana seleccionada",
    "dashboards.weekly.user-profile-year-average": "Promedio anual del perfil de usuario",
    "dashboards.weekly.year-average": "Promedio del a\u00f1o",
    "dashboards.with-partner": "Con pareja",
    "dashboards.with-family": "Con familia",
    "dashboards.women": "Mujer",
    "dashboards.zip-code": "C\u00f3digo postal",
    "dashboards.zone1": "Zona 1",
    "dashboards.zone2": "Zona 2",
    "dashboards.zone3": "Zona 3",
    "dashboards.zone4": "Zona 4",
    "date": "Fecha",
    "date.from": "Desde",
    "date.from-to": "Desde el {{from}} hasta el {{to}}",
    "date.to": "Hasta",
    "days": "D\u00edas",
    "days-ago": "d\u00edas atr\u00e1s",
    "default-building": "Edificio principal",
    "december": "Diciembre",
    "december-short": "Dic",
    "default-building-required": "Edificio principal es requerido",
    "delete": "Eliminar",
    "restore": "Restaurar",
    "delivery-date": "Fecha Entrega",
    "demographic": "Demogr\u00e1fico",
    "description": "Descripci\u00f3n",
    "developer-contact": "Contacto Propiedad",
    "development": "Promoci\u00f3n",
    "development-maps": "Plano Promoci\u00f3n",
    "development.built_surface": "Sup. construida",
    "development.city": "Ciudad",
    "development.edit": "Editar promoci\u00f3n",
    "development.name": "Promoci\u00f3n",
    "development.new": "Nueva promoci\u00f3n",
    "development.useful_surface": "Sup. \u00fatil",
    "deviation": "Desviaci\u00f3n",
    "directory.edit": "Editar",
    "directory.name": "Nombre",
    "directory.new": "Nuevo",
    "discharge-date": "Inicio de obra",
    "dmr-weekly": "DMR semanal",
    "dmr-weekly.select-dates": "Seleccionar intervalo de fechas",
    "dmr-weekly.select-week": "Selecciona semana",
    "dni": "DNI",
    "doc": "Doc.",
    "document": "Documento",
    "documentation": "Documentaci\u00f3n",
    "dollars": "Dolares $",
    "done": "Completado",
    "download": "Descargar",
    "download-files": "Descargar archivos",
    "downloads": "Descargas",
    "draft": "Borrador",
    "draft-bookable": "Borrador Reserva",
    "duplicate": "Duplicar",
    "east": "Este",
    "ecop": "ECOP",
    "edit": "Editar",
    "edit-staff": "Editar Staff",
    "email": "Email",
    "email-campaign": "Campa\u00f1a mailing",
    "email-campaign.active": "Activo",
    "email-campaign.archived": "Archivada",
    "email-campaign.daily": "Diariamente",
    "email-campaign.campaign.list": "Campa\u00f1as",
    "email-campaign.date": "Fecha",
    "email-campaign.draft": "Borrador",
    "email-campaign.edit": "Editar campa\u00f1a de e-mail",
    "email-campaign.end-date": "Fecha de fin",
    "email-campaign.form.desing_template": "Dise\u00f1ar plantilla",
    "email-campaign.form.general_info": "Informaci\u00f3n general",
    "email-campaign.form.summary": "Resumen",
    "email-campaign.frequency-sending": "Frecuencia de env\u00edo",
    "email-campaign.hourly": "Cada hora",
    "email-campaign.htmlBody.design": "Dise\u00f1a tu e-mail",
    "email-campaign.htmlBody.file_import": "Importar plantilla",
    "email-campaign.htmlBody.init_greeting": "Edita tu e-mail aqu\u00ed :)",
    "email-campaign.htmlBody.params": "Par\u00e1metros de e-mail",
    "email-campaign.htmlBody.preview": "Previsualizaci\u00f3n",
    "email-campaign.name": "Nombre campa\u00f1a",
    "email-campaign.new": "Nueva campa\u00f1a de e-mailing",
    "email-campaign.not_found": "Campa\u00f1as de e-mailing no encontradas",
    "email-campaign.params.help.html": "Introduce estos c\u00f3digos para a\u00f1adir variables en la plantilla HTML",
    "email-campaign.params.help.subject": "Introduce estos c\u00f3digos para a\u00f1adir variables en el asunto del e-mail",
    "email-campaign.params.html.center_name": "A\u00f1ade el nombre del centro",
    "email-campaign.params.html.unsub-link": "Agregue el enlace para cancelar la suscripci\u00f3n",
    "email-campaign.params.html.user_email": "A\u00f1ade el e-mail del usuario",
    "email-campaign.params.html.user_name": "A\u00f1ade el nombre del usuario",
    "email-campaign.params.subject.barcode_image": " A\u00f1adir imagen c\u00f3digo barras",
    "email-campaign.params.subject.center_name": "A\u00f1ade el nombre del centro",
    "email-campaign.params.subject.qr_image": "A\u00f1adir imagen qr",
    "email-campaign.params.subject.reward-code": "Agrega el c\u00f3digo de recompensa",
    "email-campaign.params.subject.user_code": "A\u00f1adir el c\u00f3digo de usuario",
    "email-campaign.params.subject.user_email": "A\u00f1ade el e-mail del usuario",
    "email-campaign.params.subject.user_name": "A\u00f1ade el nombre del usuario",
    "email-campaign.scheduled": "Programada",
    "email-campaign.segment": "Segmento",
    "email-campaign.segment.list": "Lista de segmentos",
    "email-campaign.segment.segmentName": "Nombre del segmento",
    "email-campaign.send.save_and_test": "Guardar y enviar prueba",
    "email-campaign.sending-date": "Fecha de env\u00edo",
    "email-campaign.sentDatetime": "Fecha de env\u00edo",
    "email-campaign.single-sending": "Env\u00edo \u00fanico",
    "email-campaign.start-date": "Fecha de inicio",
    "email-campaign.statistic.blocked": "Bloqueados",
    "email-campaign.statistic.bounced": "Rebotados",
    "email-campaign.statistic.clicked": "Clicados",
    "email-campaign.statistic.delivered": "Entregados",
    "email-campaign.statistic.opened": "Abiertos",
    "email-campaign.statistic.spam": "Correo no deseado",
    "email-campaign.subject": "Asunto",
    "email-campaign.warning-unsub-link": "\u00a1Atenci\u00f3n! La plantilla actual no tiene un enlace para darse de baja. Por favor, agregue uno antes de publicar la campa\u00f1a.",
    "email-campaign.weekly": "Cada semana",
    "email-required": "Email obligatorio",
    "email-template.not_found": "No se han encontrado plantillas",
    "email-template.select_one": "Selecciona una plantilla",
    "email-template.send.select_mail": "Introduce la direcci\u00f3n de correo a la que enviar el e-mail de prueba",
    "email-template.send.test": "Enviar e-mail de prueba",
    "email-template.type.send_contact": "Mensaje desde la aplicaci\u00f3n m\u00f3vil",
    "email-template.type.send_intranet_communication_email": "Notificaci\u00f3n de comunicaci\u00f3n de Intranet",
    "email-template.type.send_proposals_report": "Informe de cupones",
    "email-template.type.send_reprint_tickets": "Notificaci\u00f3n de reimpresi\u00f3n de boleta",
    "email-template.type.send_staff_connection_warning": "Aviso de conexi\u00f3n",
    "email-template.type.send_staff_reset_password_email": "Enviar mail reset contrase\u00f1a",
    "email-template.type.send_user_activate_email": "Mensaje de activaci\u00f3n del usuario de la aplicaci\u00f3n m\u00f3vil",
    "email-template.type.send_user_child_birthday_reminder": "Recordatorio de cumplea\u00f1os de ni\u00f1o asociado a cliente",
    "email-template.type.send_user_child_birthday_visit_ack": "Agradecimiento de visita tras el cumplea\u00f1os de un ni\u00f1o asociado a cliente",
    "email-template.type.send_user_registration_email": "Mensaje de registro de cliente",
    "email-template.type.send_user_reset_password_email": "Mensaje de reseteo de contrase\u00f1a a usuario de la aplicaci\u00f3n m\u00f3vil",
    "email-template.type.send_user_unsubscribe_email": "Cancelar suscripci\u00f3n de correo electr\u00f3nico de contacto",
    "email.not-exist": "El correo no existe",
    "email.recovery-sent": "El correo de recuperaci\u00f3n ha sido enviado",
    "emailing-campaign.filters": "Filtros",
    "emailing.campaigns.copy-of": "Copia de",
    "emailing.campaigns.duplicate-error": "Error al duplicar campa\u00f1a",
    "emailing.campaigns.stop-error": "Error al parar campa\u00f1a",
    "end-date": "Fecha final",
    "energy-certification": "Certificaci\u00f3n Energ\u00e9tica",
    "entry-time": "Hora de entrada",
    "error.channels.required": "Canal requerido",
    "error.child.bdate.required": "Se requiere fecha de nacimiento",
    "error.child.name.required": "Nombre requerido",
    "error.child.relationship.required": "Campo requerido",
    "error.child.sex.required": "G\u00e9nero requerido",
    "error.commerce.categories.required": "Categor\u00eda obligatoria",
    "error.commerce.gender.required": "El g\u00e9nero es obligatorio",
    "error.commerce.imageUrl.required": "Url imagen obligatorio",
    "error.commerce.name.required": "Nombre obligatorio",
    "error.commerce.whatsapp-message.required": "El mensaje de whatsApp es obligatorio",
    "error.commerce.whatsapp-phone.format": "El numero de m\u00f3vil para WhatsApp no tiene c\u00f3digo de \u00e1rea. Debe tener el siguiente formato: +346xxxxxxxx",
    "error.commerce.zone.required": "Zona obligatoria",
    "error.communication.commerce.required": "Selecciona al menos una tienda",
    "error.communication.companion.required": "Algunos acompa\u00f1antes tienen datos en blanco",
    "error.coupon.commerces.required": "Tienda requerida",
    "error.coupon.text.required": "Texto is required",
    "error.coupon.usageLimit.required": "L\u00edmite de cupones es obligatorio",
    "error.crm.children.entryTime.required": "Se requiere hora de entrada",
    "error.crm.children.expectedExitTime.required": "Es necesario el tiempo de salida esperado",
    "error.dashboard.bimeId.required": "Es necesario panel Bime",
    "error.dashboard.category.required": "Es necesaria la categor\u00eda",
    "error.dashboard.name.required": "Es necesario el nombre",
    "error.dashboard.panel.required": "Es necesario el panel dashboard",
    "error.development.required": "Promoci\u00f3n es obligatorio",
    "error.email-campaign-template.htmlBody.required": "La plantilla de la campa\u00f1a no puede quedar vac\u00eda",
    "error.email-campaign-template.name.required": "El nombre no puede quedar vac\u00edo",
    "error.email-campaign.date.invalid_past": "La fecha de inicio no puede tener lugar en el pasado",
    "error.email-campaign.date.required": "La fecha no puede quedar vac\u00eda",
    "error.email-campaign.endDatetime.post_startDatetime": "La fecha de fin debe ser posterior a la de inicio",
    "error.email-campaign.htmlBody.required": "La plantilla de campa\u00f1a no puede quedar vac\u00eda",
    "error.email-campaign.name.required": "El nombre no puede quedar vac\u00edo",
    "error.email-campaign.segment.required": "El campo de segmento no puede quedar vac\u00edo",
    "error.email-campaign.subject.required": "El asunto del e-mail no puede quedar vac\u00edo",
    "error.email.invalid": "Direcci\u00f3n de correo no v\u00e1lida",
    "error.file.commerces.required": "Selecciona al menos una tienda",
    "error.file.name.required": "El nombre no puede estar en blanco",
    "error.file.not_allowed": "Archivo no permitido",
    "error.file.roles.required": "Selecciona un rol",
    "error.file.type.not_allowed": "Tipo de archivo no permitido",
    "error.file.url.required": "Se requiere un archivo",
    "error.proposal.category.required": "Categor\u00eda obligatoria",
    "error.proposal.channel.required": "Se debe seleccionar al menos un canal",
    "error.proposal.description.required": "La descripci\u00f3n es obligatoria",
    "error.proposal.end-date.after": "La fecha de finalizaci\u00f3n no puede ser anterior a la fecha de inicio",
    "error.proposal.end-date.required": "Fecha de finalizaci\u00f3n requerida",
    "error.proposal.event-end-date.after": "La fecha de finalizaci\u00f3n del evento no puede ser anterior a la fecha de inicio del evento",
    "error.proposal.event-end-date.required": "Fecha de finalizaci\u00f3n del evento requerida",
    "error.proposal.eventStartDate.required": "Fecha inicial evento es obligatorio",
    "error.proposal.gender.required": "G\u00e9nero requerido",
    "error.proposal.maximumAge.required": "Se requiere edad m\u00e1xima",
    "error.proposal.minimumAge.required": "Se requiere edad minima",
    "error.proposal.name.required": "Nombre requerido",
    "error.proposal.proposalType.required": "El tipo es obligatorio",
    "error.proposal.startDate.required": "Fecha de inicio es obligatorio",
    "error.push-notification.event-end-date.after": "Despu\u00e9s",
    "error.residential.owner.code.required": "El c\u00f3digo es obligatorio",
    "error.residential.owner.name.required": "El nombre es obligatorio",
    "error.sale.date.closed-month-error": "No puede a\u00f1adir ventas en meses cerrados",
    "error.sale.date.invalid_future": "No se pueden a\u00f1adir ventas en fechas futuras",
    "error.server": "Error del servidor",
    "error.signature.not_exist": "El usuario no tiene firma",
    "error.tickets.amount.required": "Cantidad requerida",
    "error.tickets.commerce.required": "Tienda es obligatorio",
    "error.tickets.date.required": "La fecha es obligatoria",
    "error.user.cp.required": "El c\u00f3digo postal obligatorio",
    "error.user.email.required": "El email es obligatorio",
    "error.user.gdpr": "El check GDPR debe ser aceptado",
    "error.user.lastName.required": "El apellido es obligatorio",
    "error.user.name.required": "El nombre es obligatorio",
    "error.user.personType.required": "El tipo de persona es obligatorio",
    "error.user.phone.required": "El tel\u00e9fono es obligatorio",
    "error.user.registration-date.required": "La fecha de registro es obligatoria",
    "error_browser_iframe": "Tu navegador no soporta iframes",
    "estate": "Estado",
    "estimated-cost": "Coste estimado",
    "estimated-stay-time": "Tiempo estimado de permanencia",
    "eula": "T\u00e9rminos y condiciones",
    "euros": "Euros \u20ac",
    "excel": "Excel",
    "exit-time": "Tiempo de salida",
    "expire": "Expirar",
    "export": "Exportar",
    "external-api": "External API",
    "extra-info": "Extra info",
    "family": "Con familia",
    "featured": "Destacada",
    "february": "Febrero",
    "february-short": "Feb",
    "file": "Archivo",
    "file.creator_id": "Creador",
    "file.description-optional": "Descripci\u00f3n opcional",
    "file.edit": "Editar archivo",
    "file.name": "Nombre",
    "file.new": "Nuevo archivo",
    "file.roles.all": "Todos",
    "file.roles.commerce": "Tienda",
    "file.roles.management": "Gesti\u00f3n",
    "file.roles_with_access": "Roles con acceso",
    "file.shop_with_access": "Tiendas con acceso",
    "file.size": "Tama\u00f1o",
    "file.type": "Tipo",
    "file.updated_at": "Fecha",
    "file.visibility": "Visibilidad",
    "file_max_allowed": "El tama\u00f1o m\u00e1ximo permitido es {{size}}",
    "files": "Archivos",
    "files.add-folder": "A\u00f1adir carpeta",
    "filter.select": "Selecciona un filtro",
    "final-cost": "Coste final",
    "first-property": "Primer inmueble",
    "floor": "Piso",
    "floor-plans": "Planos planta",
    "flows.date": "Fecha",
    "flows.upload-xlsx-error": "El fichero que has subido no tiene un formato v\u00e1lido. Por favor, comprueba el fichero de nuevo.",
    "flows.upload-xlsx-success": "El fichero XLSX ha sido subido correctamente",
    "folder": "Carpeta",
    "folder.add": "Crear carpeta",
    "folder.new.current_dir": "Crear una nueva carpeta",
    "folder.success-created": "Creada con \u00e9xito",
    "folder_name": "Nombre de la carpeta",
    "following": "Seguimiento",
    "footfall": "Footfall",
    "footfall.cars": "Coches",
    "footfall.cinemas": "Cines",
    "footfall.import-xlsx": "Importar XLSX",
    "footfall.info": "Informaci\u00f3n",
    "footfall.xlsx-format": "El XLSX debe tener el siguiente formato:",
    "footfall.xlsx.columns": "Deber\u00eda tener cuatro columnas, la fecha en la primera columna, football en la segunda columna, coches en la tercera y cines en la \u00faltima. En la primera fila debe aparecer el nombre de la columna",
    "footfall.xlsx.date-format": "La fecha debe indicarse en Excel con el tipo 'fecha'.",
    "footfall.xlsx.download-example": "Descargar Plantilla",
    "footfall.xlsx.values-format": "La fecha debe indicarse en Excel con tipo 'n\u00famero'",
    "forgot-password": "\u00bfHas olvidado tu contrase\u00f1a?",
    "form-dashboard.bime-dashboard": "Panel Bime",
    "form-dashboard.button.clear": "Borrar",
    "form-dashboard.button.submit": "Enviar",
    "form-dashboard.category": "Categor\u00eda",
    "form-dashboard.header.available_graphs": "Gr\u00e1ficos disponibles",
    "form-dashboard.name": "Nombre del panel",
    "form-dashboard.parameters": "Par\u00e1metros",
    "friday": "Viernes",
    "friday-short": "Vie",
    "from-unit-delivery-date": "From unit delivery date",
    "from-unit-opening-date": "From unit opening date",
    "garage-plan": "Planos garaje",
    "garages-number": "N\u00ba Garajes",
    "gender": "G\u00e9nero",
    "general-data": "Datos generales",
    "general-info": "Info general",
    "general-terms-of-online-contracting": "Terminos generales de contrato online",
    "girl": "Ni\u00f1a",
    "goal-value": "Valor del objetivo",
    "grandchild": "Nieto\/a",
    "growth": "Crecimiento",
    "guard": "Guardia",
    "guard.chose_staff_member": "Elige un miembro del personal del centro...",
    "guard.no_assigned_this_day": "No hay guardia asignada para este d\u00eda",
    "has-email": "Tiene email",
    "header": "Cabecera",
    "how-to-arrive-plan": "Como llegar",
    "id": "id",
    "image": "Imagen",
    "image-required": "Imagen obligatoria",
    "images": "Im\u00e1genes",
    "influence-zone": "Zonas de influencia",
    "info.default.error.message": "Se ha producido un error. Por favor int\u00e9ntelo mas tarde",
    "info.default.error.title": "Error",
    "info.default.succes.title": "\u00c9xito",
    "info.default.success.message": "La operaci\u00f3n se realiz\u00f3 con \u00e9xito",
    "informative-message.change-password.success.message": "Tu password ha sido cambiada. Te redirigimos a la pantalla de login",
    "informative-message.close-month.error.message": "Ocurri\u00f3 un error al cerrar el mes. Por favor int\u00e9ntalo de nuevo m\u00e1s tarde",
    "informative-message.close-month.success.message": "El mes fue cerrado con \u00e9xito",
    "informative-message.export-crm-csv.success.message": "Recibir\u00e1s el fichero CSV a trav\u00e9s de tu correo electr\u00f3nico",
    "informative-message.reject-close-month.error.message": "Ocurri\u00f3 un error al rechazar cerrar el mes. Por favor int\u00e9ntalo m\u00e1s tarde.",
    "informative-message.reject-close-month.success.message": "El cierre del mes fue rechazado con \u00e9xito. Se ha enviado una comunicaci\u00f3n a la tienda",
    "informative-message.upload-yardi.error": "El archivo de Yardi no se ha subido correctamente. Por favor, intente de nuevo o contacte con un admin.",
    "informative-message.upload-yardi.success": "El archivo de Yardi se ha subido correctamente.",
    "insert-email": "Escribe tu direcci\u00f3n de email",
    "installed.apps": "Apps instaladas",
    "internal": "Interno",
    "intranet-sales-year": "Declaraci\u00f3n de ventas anual",
    "intranet.close_month-message": "Vas a cerrar los registros de venta de {{shop}} de {{month}}.",
    "intranet.communications.accept-eula": "Quiero recibir comunicaciones electr\u00f3nicas de terceros",
    "intranet.communications.contact-it": "Se produjo un error al aceptar las comunicaciones de terceros. P\u00f3ngase en contacto con TI y env\u00edeles el siguiente error de rastreo:",
    "intranet.communications.error": "Error editando staff member",
    "intranet.communications.eula-communications-description": "Antes de utilizar el sistema de comunicaci\u00f3n de la intranet, debe aceptar los t\u00e9rminos y condiciones.",
    "intranet.communications.not-contactable-staff": "No hay personal de contacto disponible en el sistema. Por favor, actualice al menos un miembro del personal para que pueda ser contactado.",
    "intranet.communications.resent-success": "La comunicaci\u00f3n ha sido reenviada con \u00e9xito",
    "intranet.communications.terms-and-conditions": "Terminos y condiciones",
    "intranet.communications.title-eula": "Intranet Communications - Aceptaci\u00f3n de RGPD",
    "intranet.communicatios.resend-staff-members": "Seleccionar miembros externos del personal para recibir la comunicaci\u00f3n.",
    "intranet.flow.total-flows": "Mes total",
    "intranet.footfall.import": "Importar",
    "intranet.footfall.import-footfall": "Importar footfall",
    "intranet.sales-year.button-show": "Mostrar extracto de ventas anual",
    "intranet.sales-year.disclaimer": "Certificado de ventas netas (sin IVA) realizado en la tienda de referencia durante un per\u00edodo de tiempo espec\u00edfico, que coincide con precisi\u00f3n con los documentos de respaldo y las notas contables en los registros de la empresa. Ventas netas (IVA no incluido)",
    "intranet.sales-year.validate.acceptable": "Desviaci\u00f3n aceptable",
    "intranet.sales-year.validate.invalid": "La declaraci\u00f3n no es v\u00e1lida.",
    "intranet.sales-year.validate.valid": "La declaraci\u00f3n es correcta.",
    "intranet.sales-year.warn-pending-review": "El administrador est\u00e1 revisando la declaraci\u00f3n de ventas anual y no es posible modificarla en este momento.",
    "intranet.sales-years.declared": "Ventas declaradas",
    "intranet.sales-years.deviation-level": "Nivel de desviacion",
    "intranet.sales-years.disable-sales-button": "Deshabilitar declaraci\u00f3n anual de ventas",
    "intranet.sales-years.disable-sales-years-confirmation": "Al confirmar, el proceso de declaraci\u00f3n de ventas anual estar\u00e1 terminado. Despu\u00e9s de esto, las tiendas no podr\u00e1n modificar su estado de ventas anual y sus ventas para el a\u00f1o",
    "intranet.sales-years.dmi": "DMI",
    "intranet.sales-years.enable-sales-button": "Habilitar  declaraci\u00f3n de ventas anual",
    "intranet.sales-years.enable-sales-years-confirmation": "Al confirmar, comenzar\u00e1 el proceso de declaraci\u00f3n de ventas anual. Las tiendas pueden crear su estado de ventas anual y pueden modificar sus ventas para el a\u00f1o",
    "intranet.sales-years.invalid-sales": "El estado de ventas anual no puede comenzar debido a las siguientes tiendas sin validaci\u00f3n:",
    "intranet.sales-years.reject-communication-message": "Su estado de ventas anuales presenta diferencias con respecto a las ventas declaradas mensualmente. Verifique el importe declarado y confirme nuevamente para los siguientes meses:",
    "intranet.sales-years.reject-communication-subject": "Declaraci\u00f3n de ventas anual rechazada",
    "intranet.sales-years.reject-sale": "Rechazar",
    "intranet.sales-years.status.pending": "Pendiente",
    "intranet.sales-years.status.rejected": "Rechazado",
    "intranet.sales-years.status.unrequested": "No solicitado",
    "intranet.sales-years.status.validated": "Validado",
    "intranet.sales-years.update-dmi-error-message": "Hubo un problema con la actualizaci\u00f3n y el valor DMI no se ha actualizado. Por favor, p\u00f3ngase en contacto con el equipo de soporte.",
    "intranet.sales-years.update-dmi-success-message": "El valor DMI se ha actualizado correctamente.",
    "intranet.sales-years.update-dmi-value": "Actualizar el valor de DMI",
    "intranet.sales-years.update-yardi": "Quiero subir el archivo Yardi (puede tardar unos minutos)",
    "intranet.sales-years.validate-sale": "Validar",
    "intranet.sales.declare-yearly-sales": "Declarar estado de ventas anuales",
    "intranet.sales.download-csv": "Descargar CSV",
    "intranet.sales.download-sales": "Descargar Ventas",
    "intranet.sales.import": "Importar ventas",
    "intranet.sales.import-sales": "Importar ventas",
    "intranet.sales.select-dates": "Seleccionar rango de fechas",
    "intranet.update_dmi-message": "Vas a actualizar el valor DMI de {{shop}} de {{month}}.",
    "invalid-token": "El token no es v\u00e1lido",
    "investor": "Inversor",
    "is": "es",
    "is-in-play-center": "Est\u00e1 en la ludoteca",
    "is_not": "no es",
    "january": "Enero",
    "january-short": "Ene",
    "job-title": "Cargo Empresa",
    "july": "Julio",
    "job-title-required": "Cargo empresa obligatorio",
    "july-short": "Jul",
    "june": "Junio",
    "june-short": "Jun",
    "kpi": "KPI",
    "kpi-tech": "KPI t\u00e9cnico",
    "kpi.tooltip.active-users": "N\u00famero de usuarios que tienen la app activa en el periodo seleccionado",
    "kpi.tooltip.app-users": "N\u00famero de usuarios registrados a trav\u00e9s de la App en el per\u00edodo seleccionado",
    "kpi.tooltip.average-visit-time": "Tiempo medio de permanencia en el centro comercial en el periodo seleccionado",
    "kpi.tooltip.downloads": "Descargas de la app en el periodo seleccionado",
    "kpi.tooltip.dwell-time": "Tiempo medio de permanencia en el centro comercial en el periodo seleccionado",
    "kpi.tooltip.installed-apps": "N\u00famero de registros en la app en el periodo seleccionado",
    "kpi.tooltip.play-center-users": "N\u00famero de usuarios registrados a trav\u00e9s de la ludoteca en el per\u00edodo seleccionado",
    "kpi.tooltip.recurrence": "N\u00famero medio de veces que los usuarios visitan el centro comercial en el periodo seleccionado",
    "kpi.tooltip.registered-app-users": "Nuevos usuarios registrados desde la app en el periodo seleccionado",
    "kpi.tooltip.registered-users-total": "N\u00famero de usuarios registrados en el periodo seleccionado",
    "kpi.tooltip.rother-users": "N\u00famero de usuarios registrados a trav\u00e9s de otros canales en el periodo seleccionado",
    "kpi.tooltip.social-users": "N\u00famero de usuarios registrados a trav\u00e9s de Social Wifi en el periodo seleccionado",
    "kpi.tooltip.visits": "N\u00famero de visitas al centro comercial en el periodo seleccionado",
    "kpi.tooltip.web-users": "N\u00famero de usuarios registrados a trav\u00e9s de la web en el periodo seleccionado",
    "kpi.vs-last-month": "VS mes pasado",
    "kpi.vs-same-month-last-year": "VS mismo mes a\u00f1o pasado",
    "kyc": "KYC",
    "label": "Etiqueta",
    "last-activity": "\u00daltima actividad",
    "last-name": "Last name",
    "last-name-required": "Last name is required",
    "last-units": "\u00daltimas unidades",
    "leasing": "Leasing",
    "leasing.accessories": "Accesories",
    "leasing.books": "Books",
    "leasing.bowling": "Bowling",
    "leasing.center.attach-logo": "Adjuntar logo (png,jpeg,gif or jpg)",
    "leasing.childrens-apparel": "Childrens apparel",
    "leasing.cinema": "Cinema",
    "leasing.coffeeshop-icecream": "Coffeeshop icecream",
    "leasing.electronics": "Electronics",
    "leasing.fast-food": "Fast food",
    "leasing.food": "Food",
    "leasing.furniture": "Furniture",
    "leasing.hairdressers-and-beauty-services": "Hairdressers and beauty services",
    "leasing.handbags": "Handbags",
    "leasing.home": "Home",
    "leasing.home-apparel": "Home apparel",
    "leasing.jewelry-watches": "Jewelry watches",
    "leasing.lingerie": "Lingerie",
    "leasing.mens-apparel": "Mens apparel",
    "leasing.opticians-farmacy": "Opticians farmacy",
    "leasing.other-leisure": "Other leisure",
    "leasing.other-services": "Other services",
    "leasing.outlet": "Outlet",
    "leasing.perfumery-beauty-store": "Perfumery beauty store",
    "leasing.pets": "Pets",
    "leasing.phone-photo-games": "Phone photo games",
    "leasing.restaurants": "Restaurants",
    "leasing.shoes": "Shoes",
    "leasing.special-food": "Special food",
    "leasing.speciality-gift": "Speciality gift",
    "leasing.sports": "Sports",
    "leasing.supermarket": "Supermarket",
    "leasing.toys": "Toys",
    "leasing.travels": "Travels",
    "leasing.womens-apparel": "Womens apparel",
    "leasing.womens-mens-apparel": "Womens & mens apparel",
    "leasing.young-apparel": "Young apparel",
    "legal": "Legal",
    "legal-person": "Persona jur\u00eddica",
    "level": "Level",
    "level-required": "Level is required",
    "lineitem": "Partida",
    "lineitem.edit": "Editar partida",
    "lineitem.new": "Nueva partida",
    "location": "Direcci\u00f3n",
    "lock": "Bloquear",
    "loyalty": "Fidelizaci\u00f3n",
    "loyalty-card": "Tarjeta de fidelizaci\u00f3n",
    "loyalty-card.campaign": "Campa\u00f1a",
    "loyalty-card.stamps": "Sellos",
    "loyalty-card.stamps.redeemable-stamp": "Sello canjeable",
    "loyalty-card.stamps.value": "Valor",
    "loyalty-cards": "Tarjetas de fidelizaci\u00f3n",
    "loyalty-cards.can-not-generate-message": "El usuario no tiene ning\u00fan sello que pueda canjear",
    "loyalty-cards.create-historic-stamps": "Crear sellos",
    "loyalty-cards.error-stamp-number": "N\u00famero de sellos (m\u00e1x .: {{max}} sellos)",
    "loyalty-cards.generate-error-message": "Hubo un problema al generar las tarjetas de fidelizaci\u00f3n y no se han creado. Por favor, p\u00f3ngase en contacto con el equipo de soporte.",
    "loyalty-cards.generate-success-message": "Las tarjetas de fidelizaci\u00f3n se han generado correctamente",
    "loyalty-cards.not-enough-stamps": "El usuario no tiene suficientes sellos para canjear por una tarjeta de fidelizaci\u00f3n",
    "loyalty-cards.preview-message": "Resumen de tarjetas de fidelizaci\u00f3n",
    "loyalty-cards.select-campaign": "Selecciona campa\u00f1a",
    "loyalty-cards.stamp-number": "N\u00famero de sellos",
    "loyalty-cards.stamps": "Sellos",
    "loyalty.exchange-stamps": "Canjear sellos",
    "mail-notifications": "Comunicaciones electr\u00f3nicas comerciales",
    "mail_notifications": "Comunicaciones electr\u00f3nicas comerciales",
    "mail_partners_notifications": "Comunicaciones electr\u00f3nicas de terceros",
    "management": "Gesti\u00f3n",
    "man": "Hombre",
    "manager": "Manager",
    "march": "Marzo",
    "march-short": "Mar",
    "marketing": "Marketing",
    "marketing-analytics.campaign.attach-image": "Adjuntar imagen (png,jpeg,gif or jpg)",
    "marketing-analytics.campaign.active-crm": "Activo en CRM",
    "marketing-analytics.campaign.closing-notes": "Notas de cierre",
    "marketing-analytics.campaign.closure.actions": "Acciones",
    "marketing-analytics.campaign.closure.attr-required": "Se requieren los siguientes atributos: {{requiredAttrs}}",
    "marketing-analytics.campaign.closure.campaign": "Campa\u00f1a",
    "marketing-analytics.campaign.closure.is-goal": "Es objetivo",
    "marketing-analytics.campaign.closure.manual-kpis": "Manual KPIs",
    "marketing-analytics.campaign.closure.suppliers": "Proveedores",
    "marketing-analytics.campaign.date-helper": "Recuerde revisar las fechas de las acciones si cambia el intervalo de fechas de la campa\u00f1a",
    "marketing-analytics.campaign.files": "Im\u00e1genes de campa\u00f1a",
    "marketing-analytics.campaign.generic-reward": "Retribuci\u00f3n gen\u00e9rica",
    "marketing-analytics.campaign.loyalty-campaign": "Campa\u00f1a de tarjeta de fidelidad",
    "marketing-analytics.campaign.loyalty-campaign-info-message": "Seleccionando una campa\u00f1a de tarjeta de fidelidad generar\u00e1 una recompensa cuando se logre la tarjeta de fidelidad",
    "marketing-analytics.campaign.loyalty.amount": "Cantidad",
    "marketing-analytics.campaign.loyalty.receipts-redemption-days": "D\u00edas de carencia de factura",
    "marketing-analytics.campaign.loyalty.welcome-stamps": "Sellos de bienvenida",
    "marketing-analytics.campaign.max-capacity": "Capacidad m\u00e1xima",
    "marketing-analytics.campaign.raffle.block-raffle-info": "No podr\u00e1 modificar la configuraci\u00f3n del sorteo una vez que se haya generado la primera factura. Tenga esto en cuenta al guardar y lanzar esta campa\u00f1a",
    "marketing-analytics.campaign.raffle.general_data": "Datos generales",
    "marketing-analytics.campaign.raffle.loyalty-code": "C\u00f3digo de fidelidad",
    "marketing-analytics.campaign.raffle.rules": "Excepciones del sorteo",
    "marketing-analytics.campaign.rating": "Calificaci\u00f3n de campa\u00f1a",
    "marketing-analytics.campaign.reward.other-options": "otras opciones",
    "marketing-analytics.campaign.reward.valid-days": "D\u00edas v\u00e1lidos",
    "marketing-analytics.campaign.search-campaign": "Buscar Campa\u00f1a",
    "marketing-analytics.campaign.select-campaign": "Seleccionar campa\u00f1a",
    "marketing-analytics.campaign.share-campaign": "Compartir campa\u00f1a",
    "marketing-analytics.campaign.submit-image": "Enviar imagen",
    "marketing-analytics.campaign.without-remains": "Sin remanente",
    "marketing-analytics.campaigns.actions": "Acciones de campa\u00f1a",
    "marketing-analytics.campaigns.follow-up": "Seguimiento",
    "marketing-analytics.campaigns.goal-chart": "Seguimiento de KPI objetivo",
    "marketing-analytics.campaigns.goals": "Objetivos de campa\u00f1a",
    "marketing-analytics.campaigns.kpi": "KPI",
    "marketing-analytics.campaigns.loyalty": "Loyalty",
    "marketing-analytics.kpi.active-users": "DMI - Usuarios activos",
    "marketing-analytics.kpi.attendant-number": "Campa\u00f1a - Asistente",
    "marketing-analytics.kpi.contestants": "Campa\u00f1a - Participantes",
    "marketing-analytics.kpi.cost-per-contestant": "Campa\u00f1a - Coste \/ participante",
    "marketing-analytics.kpi.coupon-exchanges": "DMI - Uso de cupones",
    "marketing-analytics.kpi.coupon-percent": "DMI - Cup\u00f3n(%)",
    "marketing-analytics.kpi.eca-number": "Campa\u00f1a - Publicidad",
    "marketing-analytics.kpi.impressions-number": "DMI - Impresiones",
    "marketing-analytics.kpi.manual-kpis": "KPIs manual",
    "marketing-analytics.kpi.new-registered-users-percent": "Campa\u00f1a - Nuevos usuarios (%)",
    "marketing-analytics.kpi.new-registered-users": "Campa\u00f1a - Nuevos usuarios",
    "marketing-analytics.kpi.previous-registered-users": "Campa\u00f1a - Usuarios antiguos",
    "marketing-analytics.kpi.receipt-average-price": "Campa\u00f1a - Factura media",
    "marketing-analytics.kpi.tooltip.active-users": "N\u00famero de usuarios activos en la app durante el periodo de la campa\u00f1a.",
    "marketing-analytics.kpi.tooltip.attendant": "N\u00famero total de personas que han asistido al evento.",
    "marketing-analytics.kpi.tooltip.contestants": "Evento - Participantes",
    "marketing-analytics.kpi.tooltip.cost-per-contestant": "Valor medio del coste de la campa\u00f1a por cada participante",
    "marketing-analytics.kpi.tooltip.coupon-exchanges": "N\u00famero de cupones canjeados",
    "marketing-analytics.kpi.tooltip.coupon-percent": "DMI - Cupones (%)",
    "marketing-analytics.kpi.tooltip.eca": "Valor publicitario no pagado de la campa\u00f1a",
    "marketing-analytics.kpi.tooltip.eceipt-average-price": "Valor medio de las facturas por participante durante la campa\u00f1a",
    "marketing-analytics.kpi.tooltip.impressions-number": "N\u00famero de impresiones en la app durante el per\u00edodo de la campa\u00f1a",
    "marketing-analytics.kpi.tooltip.new-registered-users": "Nuevos usuarios registrados durante la campa\u00f1a",
    "marketing-analytics.kpi.tooltip.new-registered-users-percent": "Nuevos usuarios registrados durante la campa\u00f1a frente a usuarios antiguos",
    "marketing-analytics.kpi.tooltip.previous-registered-users": "N\u00famero de usuarios antiguos que han participado en la campa\u00f1a",
    "marketing-analytics.kpi.tooltip.total-receipts": "N\u00famero de facturas registradas y valor total",
    "marketing-analytics.kpi.tooltip.total-receipts-value": "Valor total de las facturas registradas",
    "marketing-analytics.kpi.tooltip.visits": "N\u00famero de visitas al centro de clientes con la app",
    "marketing-analytics.kpi.total-receipts": "Campa\u00f1a - Facturas",
    "marketing-analytics.kpi.total-receipts-value": "Campa\u00f1a - Valor facturas",
    "marketing-analytics.kpi.visits": "DMI - Visitas",
    "marketing-analytics.kpis.reference-campaign": "El valor de referencia es el dato de la campa\u00f1a {{campaign}} que dur\u00f3 desde {{startDate}} hasta {{endDate}}",
    "marketing-analytics.kpis.reference-dates": "El valor de referencia son los datos de {{startDate}} a {{endDate}}",
    "marketing-analytics.report": "Informe",
    "marketing-analytics.start-date-tooltip": "El intervalo de fechas de la campa\u00f1a incluye tanto los eventos durante la campa\u00f1a como las acciones anteriores.",
    "marketing-dossier": "Marketing dossier",
    "match-pass": "Las password no coincide",
    "max-image-size-1440-800": "Tama\u00f1o imagen 1440 x 800px",
    "max-image-size-300-232": "Imagen miniatura es 300 x 232px",
    "maximumAge": "Edad m\u00e1xima",
    "may": "Mayo",
    "may-short": "Mayo",
    "menu.navbar.analytics": "Analytics",
    "menu.navbar.bi": "Marketing analytics",
    "menu.navbar.bi.campaign.index": "Campa\u00f1as",
    "menu.navbar.bi.lineitem.index": "Partidas",
    "menu.navbar.bi.service.index": "Servicios",
    "menu.navbar.bi.supplier.index": "Proveedores",
    "menu.navbar.bonus-form": "Formulario de bonificaci\u00f3n",
    "menu.navbar.bonus-requests": "Peticiones de bonificaci\u00f3n",
    "menu.navbar.category.new": "Nueva categor\u00eda",
    "menu.navbar.category.show": "Categor\u00edas",
    "menu.navbar.center.new": "Nuevo centro",
    "menu.navbar.channel.show": "Canales",
    "menu.navbar.cms": "CMS",
    "menu.navbar.cms.commerce-stay-alert.index": "Alerta de estancia en tienda",
    "menu.navbar.configuration": "Configuraci\u00f3n",
    "menu.navbar.configuration.catalog": "Cat\u00e1logo",
    "menu.navbar.configuration.center": "Informaci\u00f3n del centro",
    "menu.navbar.configuration.dashboards": "Paneles",
    "menu.navbar.configuration.extra": "Atributos extra",
    "menu.navbar.configuration.influencezone": "Zonas de influencia",
    "menu.navbar.configuration.module": "M\u00f3dulos",
    "menu.navbar.configuration.password": "Cambiar contrase\u00f1a",
    "menu.navbar.configuration.shop.import": "Importar tiendas",
    "menu.navbar.configuration.zone": "Zonas",
    "menu.navbar.consumption.show": "Consumo",
    "menu.navbar.coupon.report": "Cupones",
    "menu.navbar.crm": "CRM",
    "menu.navbar.crm.campaign.index": "Campa\u00f1as",
    "menu.navbar.customer.import": "Importar clientes",
    "menu.navbar.customer.show": "Lista de clientes",
    "menu.navbar.customertype.show": "Tipos de cliente",
    "menu.navbar.desktop": "Escritorio",
    "menu.navbar.emailing": "Mailing",
    "menu.navbar.email-template.show": "Plantillas de correo electr\u00f3nico",
    "menu.navbar.emailing.campaign.index": "Campa\u00f1as de e-mailing",
    "menu.navbar.footfall": "Footfall",
    "menu.navbar.intranet": "Intranet",
    "menu.navbar.intranet.communication.index": "Comunicaciones",
    "menu.navbar.intranet.file.index": "Archivos",
    "menu.navbar.intranet.guard.index": "Guardias",
    "menu.navbar.intranet.sale.index": "Ventas",
    "menu.navbar.intranet.sales-year.index": "Declaraci\u00f3n de ventas anual",
    "menu.navbar.intranet.subtype.index": "Subtipos de comunicaciones",
    "menu.navbar.logout": "Cerrar sesi\u00f3n",
    "menu.navbar.marketing-analytics": "Marketing analytics",
    "menu.navbar.marketing-analytics.campaign.index": "Campa\u00f1as",
    "menu.navbar.marketing-analytics.campaigntype.index": "Tipos de campa\u00f1a",
    "menu.navbar.marketing-analytics.lineitem.index": "Partidas",
    "menu.navbar.marketing-analytics.service.index": "Servicios",
    "menu.navbar.marketing-analytics.supplier.index": "Proveedores",
    "menu.navbar.playcenter.show": "Ludoteca",
    "menu.navbar.proposal.new": "Nueva propuesta",
    "menu.navbar.proposal.show": "Propuestas",
    "menu.navbar.proposal.show.active": "Propuestas activas",
    "menu.navbar.proposal.show.draft": "Propuestas en borradores",
    "menu.navbar.proposal.show.expired": "Propuestas expiradas",
    "menu.navbar.proposal.show.pending": "Propuestas pendientes ",
    "menu.navbar.proposal.show.scheduled": "Propuestas planificadas",
    "menu.navbar.push-notification.index": "Notificaciones push",
    "menu.navbar.recommendation.show": "Recomendaciones",
    "menu.navbar.residential": "Residencial",
    "menu.navbar.residential.campaigns.index": "Campa\u00f1as",
    "menu.navbar.residential.contacts": "Contactos",
    "menu.navbar.residential.developments.index": "Promociones",
    "menu.navbar.residential.owners.index": "Propietarios",
    "menu.navbar.residential.properties.index": "Inmuebles",
    "menu.navbar.shop.show": "Lista de tiendas",
    "menu.navbar.staff.new": "Nuevo usuario",
    "menu.navbar.staff.show": "Usuarios",
    "menu.navbar.traffic.blacklist": "Usuarios sin seguimiento",
    "menu.navbar.traffic.show": "Tr\u00e1fico",
    "minimumAge": "Edad m\u00ednima",
    "monday": "Lunes",
    "monday-short": "Lun",
    "month": "Mes",
    "month.state": "Estado Mes",
    "monthly-report": "Informe mensual",
    "mstatus": "Estado civil",
    "multi_family": "Plurifamiliar",
    "multimedia": "Multimedia",
    "multimedia-files": "Archivos Multimedia",
    "name": "Nombre",
    "name-required": "Nombre obligatorio",
    "name_required": "El nombre no puede estar en blanco",
    "nationality": "Nacionalidad",
    "nationality_id": "Nacionalidad",
    "nephew": "Sobrino\/a",
    "new-staff": "New staff",
    "new_folder": "Nueva carpeta",
    "nif": "NIF",
    "no": "No",
    "no-data": "No hay datos disponibles con los filtros seleccionados",
    "none": "Ninguno",
    "north": "Norte",
    "not-found": "No encontrado",
    "not_between": "no entre",
    "not_contains": "no contiene",
    "november": "Noviembre",
    "november-short": "Nov",
    "occupation-percentage": "% ocupaci\u00f3n",
    "october": "Octubre",
    "october-short": "Oct",
    "on-signing-date": "Fecha firma",
    "open": "Abierta",
    "options_not_found": "No se han encontrado opciones",
    "or_less": "o menos",
    "or_more": "o m\u00e1s",
    "other": "Otros",
    "other-data": "Otros Datos",
    "others": "Otros",
    "overview": "Vista general",
    "owner": "Propietario",
    "owner-code": "C\u00f3digo propietario",
    "pager.out_of": "de",
    "pass": "Contrase\u00f1a",
    "pass-format": "Use 10 o m\u00e1s letras, con al menos 1 n\u00famero, 1 letra may\u00fascula y 1 letra min\u00fascula",
    "pass-required": "Debe haber al menos 10 caracteres, 1 may\u00fascula, 1 min\u00fascula y 1 n\u00famero",
    "passport": "Pasaporte",
    "password": "Password",
    "payment-plan": "Plan de pago",
    "password-format": "Please make a strong password that contains at least 10 characters, one capital letter, one lower letter and one number",
    "payslip": "N\u00f3mina",
    "pdf": "PDF",
    "pending": "Pendiente",
    "person-type": "Tipo de persona",
    "phone": "Tel\u00e9fono",
    "physical-person": "Persona f\u00edsica",
    "pin": "PIN",
    "play-center-stays": "Ludoteca",
    "play-center.time": "Tiempo",
    "play-center.tutor": "Tutor",
    "position": "Posici\u00f3n",
    "postal-code": "C. Postal",
    "powerpoint": "Powerpoint",
    "presentation": "Presentaci\u00f3n",
    "properties": "Propiedades",
    "properties-number": "N\u00ba Inmuebles",
    "property": "Inmueble",
    "property-filter.bedrooms_number": "Dormitorios",
    "property-filter.bathrooms_number": "Ba\u00f1os",
    "property-filter.built_surface": "Superficie construida",
    "property-filter.city": "Ciudad",
    "property-filter.price": "Precio",
    "property-filter.property_type": "Tipo de inmueble",
    "property-filter.province": "Provincia",
    "property-filter.residential_development": "Promoci\u00f3n",
    "property-filter.toilets_number": "Aseos",
    "property-filter.useful_surface": "Superficie \u00fatil",
    "property-types": "Tipos",
    "property.bathrooms-number": "Ba\u00f1os",
    "property.bedrooms-number": "Dormitorios",
    "property.block": "Bloque",
    "property.built-surface": "Superficie construida",
    "property.buyer": "Comprador",
    "property.buyer-search": "Buscar compradores",
    "property.cadastral-reference": "Referencia catastral",
    "property.common-zones-surface": "Superficie de zonas comunes",
    "property.contact": "Contacto",
    "property.data": "Datos del inmueble",
    "property.detailed-spaces": "Espacios detallados",
    "property.doorway": "Portal",
    "property.estate": "Finca",
    "property.floor": "Piso",
    "property.garages-number": "Garajes",
    "property.ladder": "Escalera",
    "property.letter": "Letra",
    "property.name": "Nombre del inmueble",
    "property.orientation": "Orientaci\u00f3n",
    "property.price": "Precio",
    "property.propertyType": "Tipo de inmueble",
    "property.reference": "Referencia",
    "property.rental": "Precio de alquiler",
    "property.reserved-date": "Fecha reservada",
    "property.room.name": "Nombre del espacio",
    "property.room.select_type": "Selecciona un tipo de espacio",
    "property.room.surface": "Superficie",
    "property.room.type": "Tipo",
    "property.select_orientation": "Selecciona",
    "property.select_propertyState": "Selecciona un estado del inmueble",
    "property.select_propertyType": "Selecciona tipo de inmueble",
    "property.select_residential-development": "Selecciona una promoci\u00f3n",
    "property.state": "Estado",
    "property.storage-rooms-number": "Trastero",
    "property.technical-data": "Datos t\u00e9cnicos",
    "property.terrace": "Terraza \/ Jard\u00edn",
    "property.toilets-number": "Aseos",
    "property.useful-surface": "Superficie \u00fatil",
    "property.user": "Usuario",
    "proposal": "Propuesta",
    "proposal.app-image": "Imagen de la App",
    "proposal.coupon": "Cup\u00f3n",
    "proposal.coupon-exchanges": "Canjeo de cup\u00f3n",
    "proposal.coupon.limit": "L\u00edmite  cupones",
    "proposal.custom-target": "P\u00fablico objetivo",
    "proposal.edit": "Editar propuesta",
    "proposal.end-date": "Fecha de fin",
    "proposal.end-datetime": "Fin de la fecha",
    "proposal.event": "Evento",
    "proposal.event-end-event-datetime": "Fecha de fin de evento",
    "proposal.event-start-event-datetime": "Fecha de inicio de evento",
    "proposal.export-feedback": "Selecciona tipo de feedback",
    "proposal.extra.text": "Texto",
    "proposal.featured": "Destacada",
    "proposal.filters": "Filtros",
    "proposal.gender": "G\u00e9nero",
    "proposal.general-data": "Datos generales",
    "proposal.hide-home": "Ocultar del home",
    "proposal.new": "Nueva propuesta",
    "proposal.publish-on-app": "Publicar en App",
    "proposal.publish-on-web": "Publicar en Web",
    "proposal.segment-all": "Todos los usuarios",
    "proposal.segment.list": "Lista de segmentos",
    "proposal.segments": "Segmentos",
    "proposal.settings": "Ajustes",
    "proposal.start-date": "Fecha de inicio",
    "proposal.start-datetime": "Inicio de la fecha",
    "proposal.store-search": "Buscar tienda",
    "proposal.type": "Tipo",
    "proposal.web-image": "Imagen web",
    "province": "Provincia",
    "province_id": "Provincia",
    "publish": "Publicar",
    "published": "Publicado",
    "purchase-agreement": "Contrato compra",
    "purchaser-type": "Tipo de comprador",
    "push-notification": "Notificaci\u00f3n push",
    "push-notification.action-data.proposal": "Propuesta",
    "push-notification.action-type": "Tipo de acci\u00f3n",
    "push-notification.action-type.default": "Por defecto",
    "push-notification.action-type.proposal": "Propuesta",
    "push-notification.active": "Activo",
    "push-notification.active-app-users": "usuarios activos",
    "push-notification.advanced-options": "Opciones avanzadas",
    "push-notification.archived": "Archivada",
    "push-notification.campaign": "Campa\u00f1a",
    "push-notification.date": "Fecha",
    "push-notification.daily": "Diariamente",
    "push-notification.draft": "Borrador",
    "push-notification.edit": "Editar",
    "push-notification.end-date": "Fecha de fin",
    "push-notification.end-datetime": "Fecha de fin",
    "push-notification.filters": "Filtros",
    "push-notification.frequency": "Frecuencia",
    "push-notification.general-data": "Datos generales",
    "push-notification.hourly": "Cada hora",
    "push-notification.image": "Imagen",
    "push-notification.image.info": "La imagen solo se mostrar\u00e1 en la notificaci\u00f3n push cuando el tipo de acci\u00f3n seleccionado sea \"por defecto\"",
    "push-notification.message": "Mensaje",
    "push-notification.name": "Nombre",
    "push-notification.new": "Nueva notificaci\u00f3n push",
    "push-notification.on-end-visit": "Al terminar la visita",
    "push-notification.on-start-visit": "En el inicio de la visita",
    "push-notification.proposal-image.info": "Las im\u00e1genes de las notificaciones push no pueden tener m\u00e1s de 1 MB. Por favor, verifique el tama\u00f1o de la imagen antes de publicar la notificaci\u00f3n push",
    "push-notification.proposal-image.warning": "La imagen de la propuesta es mayor a 1 MB. Por favor, reduzca el tama\u00f1o de la imagen antes de publicar la notificaci\u00f3n push.",
    "push-notification.proposals": "Propuestas",
    "push-notification.proposals-search": "Buscar propuesta",
    "push-notification.scheduled": "Programada",
    "push-notification.segment": "Segmento",
    "push-notification.segmentation": "Segmentaci\u00f3n",
    "push-notification.single-sending": "Env\u00edo \u00fanico",
    "push-notification.start-date": "Fecha de inicio",
    "push-notification.start-datetime": "Fecha de inicio",
    "push-notification.state": "Estado",
    "push-notification.status": "Estado",
    "push-notification.subject": "Asunto",
    "push-notification.weekly": "Cada semana",
    "quality-specifications": "Memoria calidad",
    "raffle": "Sorteo",
    "rating": "Calificaci\u00f3n",
    "receipt": "Factura",
    "receipt.campaign": "Campa\u00f1a",
    "receipt.campaign.accumulated-balance": "Saldo acumulado",
    "receipt.campaign.accumulative-amount": "Cantidad acumulada",
    "receipt.create-receipt": "Crear factura",
    "receipt.pending-balance": "Saldo pendiente",
    "receipt.save-message": "El ticket se ha subido correctamente",
    "receipt.select-campaign": "Selecciona campa\u00f1a",
    "receipt.select-campaigns": "Selecciona campa\u00f1a",
    "receipt.stamps": "Sellos",
    "receipt.stamps-number": "N\u00famero de sellos",
    "receipt.status.not-yet-redeemable": "a\u00fan no canjeable",
    "receipt.status.redeemed": "Redimida",
    "receipt.tickets": "Papeletas",
    "receipts": "Facturas",
    "receipts.can-not-generate-message": "El cliente no tiene ninguna factura canjeable",
    "receipts.redeem-receipt": "Canjear facturas",
    "reference": "Referencia",
    "reference-campaign": "Campa\u00f1a de referencia",
    "reference-date": "Fecha de referencia",
    "reference-value": "Valor de referencia",
    "refresh": "Recargar",
    "register-date": "Fecha de registro",
    "reject": "Rechazar",
    "reject-close-month-modal.specify-reason": "Especifique la raz\u00f3n a la tienda",
    "reject-close-month-modal.title": "Rechazo del cierre de mes",
    "reject-publishing": "Rechazar la publicaci\u00f3n",
    "rejectPublish": "Rechazar la publicaci\u00f3n",
    "remove": "Borrar",
    "rent": "Alquiler",
    "rent-price": "Precio de alquiler",
    "rental": "Alquiler",
    "report": "Informe",
    "request": "Solicitud",
    "required": "{{attr}} obligatorio",
    "resend": "Reenviar",
    "reset-password": "Resetear Password",
    "residence_card": "Tarjeta de residencia",
    "residential-development": "En promoci\u00f3n",
    "residential-development.development": "Promoci\u00f3n",
    "residential-development.multilanguage-files": "Archivos multilenguaje",
    "residential-documentation-sent.deed-warning-text": "Tras introducir la fecha de la escritura la oferta se cerrar\u00e1.  No podr\u00e1s modificar ninguna de las fechas anteriores",
    "residential-offer-close.rejected-common-zones": "Zonas comunes",
    "residential-offer-close.rejected-delivery-date": "Fecha de entrega",
    "residential-offer-close.rejected-other": "Otro",
    "residential-offer-close.rejected-other-reason": "Otras razones que no estaban en la lista",
    "residential-offer-close.rejected-pay-form": "Formulario de pago",
    "residential-offer-close.rejected-price": "Precio",
    "residential-offer-close.rejected-project": "Proyecto",
    "residential-offer-close.rejected-reasons": "Razones rechazadas",
    "residential-offer.accepted": "Aceptado",
    "residential-offer.closing-notes": "Notas de cierre",
    "residential-offer.closingDate": "Fecha de cierre",
    "residential-offer.notes": "Notas",
    "residential-offer.open": "Abierto",
    "residential-offer.openingDate": "Fecha de apertura",
    "residential-offer.pending": "Pendiente",
    "residential-offer.price": "Precio",
    "residential-offer.rejected": "Rechazado",
    "residential-offer.select_new_state": "Seleccionar nuevo estado",
    "residential-offer.sent_info_dossier": "Dossier",
    "residential-offer.sent_info_property_map": "Mapa del inmueble",
    "residential-offer.sent_info_qualities_note": "Nota de  calidades",
    "residential-offer.signed": "Firmado",
    "residential-offer.unique": "Singular",
    "residential-offers.price": "Precio",
    "residential-owner": "Propietario",
    "residential-properties.bedrooms": "Dormitorios",
    "residential-properties.location": "Ubicaci\u00f3n",
    "residential-properties.price": "Precio",
    "residential-properties.property": "Inmueble",
    "residential-properties.rooms": "Espacios",
    "residential-properties.surface": "Superficie",
    "residential-properties.type": "Tipo",
    "residential-property.booking-documentation": "Documentaci\u00f3n de  reserva",
    "residential-property.booking-payment": "Pago de reserva",
    "residential-property.deed": "Escritura",
    "residential-property.edit": "Editar inmueble",
    "residential-property.monthly-payment-system": "Sistema de pago mensual",
    "residential-property.new": "Nuevo inmueble",
    "residential-property.state.blocked": "Bloqueado",
    "residential-property.state.free": "Disponible",
    "residential-property.state.reserved": "Reservado",
    "residential-property.state.sold": "Vendido",
    "residential-property.trading": "CCV",
    "residential-proposal.close_offer": "Cerrar oferta",
    "residential-request": "Solicitud",
    "residential-room.type.bathroom": "Ba\u00f1o",
    "residential-room.type.bedroom": "Dormitorio",
    "residential-room.type.dressing-room": "Vestidor",
    "residential-room.type.garage": "Garaje",
    "residential-room.type.hall": "Entrada",
    "residential-room.type.kitchen": "Cocina",
    "residential-room.type.laundry-room": "Lavadero",
    "residential-room.type.living-room": "Sal\u00f3n",
    "residential-room.type.lobby": "Vest\u00edbulo",
    "residential-room.type.storage": "Trastero",
    "residential-room.type.toilet": "Aseo",
    "residential-rooms-required": "Name, type and surface of each room are required",
    "residential-sale-process.document_sent": "Documentaci\u00f3n enviada",
    "residential-user-state.accepted": "Aceptado",
    "residential-user-state.new": "Nuevo",
    "residential-user-state.rejected": "Rechazado",
    "residential-user-state.open": "Abierto",
    "residential-user-state.request": "Inter\u00e9s",
    "residential-user-state.signed": "Firmado",
    "residential.add_files_button": "A\u00f1adir archivos",
    "residential.add_files_title": "A\u00f1adir archivos",
    "residential.add_link_button": "A\u00f1adir link",
    "residential.add_video_link": "A\u00f1adir url video",
    "residential.campaign.edit": "Editar campa\u00f1a",
    "residential.campaign.name": "Nombre",
    "residential.campaign.new": "Nueva Campa\u00f1a",
    "residential.community_expenses": "Gastos de comunidad",
    "residential.development": "Promoci\u00f3n",
    "residential.development.buildingState": "Estado de la construcci\u00f3n",
    "residential.development.country-search": "Buscar pa\u00eds",
    "residential.development.unique": "Singular",
    "residential.development.name": "Nombre",
    "residential.development.owner": "Propietario",
    "residential.development.province-search": "Buscar por provincia",
    "residential.development.reference": "Referencia de la  promoci\u00f3n",
    "residential.development.tu": "T.U.",
    "residential.development.zone": "Zona",
    "residential.development_maps": "Plano de promoci\u00f3n",
    "residential.developments": "Promociones",
    "residential.documentation": "Documentaci\u00f3n",
    "residential.dossiers": "Informe de Marketing",
    "residential.end_of_construction": "Fin de obra",
    "residential.energy_certification.a": "A",
    "residential.energy_certification.b": "B",
    "residential.energy_certification.c": "C",
    "residential.energy_certification.d": "D",
    "residential.energy_certification.e": "E",
    "residential.energy_certification.f": "F",
    "residential.energy_certification.g": "G",
    "residential.how_to_arrive_map": "Plan \"C\u00f3mo llegar\"",
    "residential.images": "Im\u00e1genes",
    "residential.in_construction": "En construcci\u00f3n",
    "residential.maps": "Planos de planta",
    "residential.multimedia": "Archivos y multimedia",
    "residential.owner.code": "C\u00f3digo",
    "residential.owner.edit": "Editar propietario residencial",
    "residential.owner.fiscal-name": "Nombre fiscal",
    "residential.owner.logo": "Logotipo",
    "residential.owner.name": "Nombre",
    "residential.owner.new": "Nuevo propietario residencial",
    "residential.owner.vat-number": "NIF\/CIF",
    "residential.post_sale": "Post-venta",
    "residential.presale": "Precomercializaci\u00f3n",
    "residential.properties": "Inmuebles",
    "residential.property": "Vivienda",
    "residential.property.development_documentation": "Documentaci\u00f3n de la promoci\u00f3n",
    "residential.property.development_multimedia_files": "Archivos multimedia de la promoci\u00f3n",
    "residential.property.reserved-date": "Fecha reservada",
    "residential.property.reserved-notes": "Notas",
    "residential.property_maps": "Planos del Inmueble",
    "residential.proposal.send-info": "Enviar informaci\u00f3n",
    "residential.proposal.send-info.error-sending-message": "Ocurri\u00f3 un error mientras se enviaba la informaci\u00f3n del email. Por favor intentelo de nuevo m\u00e1s tarde",
    "residential.proposal.send-info.error-updating-checks-message": "La propuesta no se ha actualizado correctamente",
    "residential.proposal.send-info.success-message": "La informaci\u00f3n del email se ha enviado correctamente",
    "residential.rooms": "Espacios",
    "residential.sale": "Venta",
    "residential.start_work": "Inicio de obra",
    "residential.zone_services": "Zona de servicio",
    "residential_offers.closing": "Oferta de cierre",
    "residential_offers.documentation-procedure": "Tr\u00e1mite documentaci\u00f3n",
    "residential_offers.new": "Nueva oferta",
    "residential_property": "Inmueble",
    "residential_proposal": "Propuesta",
    "response": "Respuesta",
    "reward": "Recompensa",
    "role": "Cargo",
    "sale": "Venta",
    "sale-process.add-request": "A\u00f1adir solicitud",
    "sale-process.closing": "Cierre",
    "sale-process.proposal": "Propuesta",
    "sale-process.proposals": "Propuestas",
    "sale-process.request.date": "Fecha",
    "sale-process.request.name": "Nombre",
    "sale-process.request.properties-available": "Disponible",
    "sale-process.request.properties-blocked": "Bloqueados",
    "sale-process.requests": "Peticiones",
    "sale.amount": "Cantidad",
    "sale.concept": "Conceptos de venta",
    "sale.date": "Fecha",
    "sale.new": "Nueva venta",
    "sales": "Ventas",
    "sales-year.new": "Nueva declaraci\u00f3n  de ventas  anual",
    "sales-year.show": "Mostrar declaraci\u00f3n  de ventas  anual",
    "sales-years.confirm-shop-sales-years": "Confirmo que la declaraci\u00f3n de ventas  anual es v\u00e1lida.",
    "sales.csv": "XLSX",
    "sales.import-xlsx": "Importar XLSX",
    "sales.info": "Informaci\u00f3n",
    "sales.sale-concepts": "Concepto de venta",
    "sales.upload-xlsx-error": "El archivo que ha subido no tiene un formato v\u00e1lido. Por favor, revise el archivo e int\u00e9ntelo de nuevo.",
    "sales.upload-xlsx-success": "\u00a1XLSX ha sido subido con \u00e9xito!",
    "sales.xlsx": "Xlsx",
    "sales.xlsx-format": "El XLSX debe tener el siguiente formato:",
    "sales.xlsx.amount-format": "El importe debe indicarse en Excel con el tipo 'moneda'.",
    "sales.xlsx.columns": "Debe tener tres columnas, con la fecha en la primera columna, el concepto de venta en la segunda columna y la cantidad en la tercera. En la primera fila debe ser el nombre de la columna.",
    "sales.xlsx.concept-format": "El concepto se identifica por su c\u00f3digo Yardi. Por defecto, este valor ser\u00e1 'gm'.",
    "sales.xlsx.date-format": "La fecha debe indicarse en Excel con el tipo 'fecha'.",
    "sales.xlsx.download-example": "Descargar plantilla",
    "same-period-last": "Mismo periodo pasado {{period}}",
    "saturday": "S\u00e1bado",
    "saturday-short": "S\u00e1b",
    "save": "Guardar {{modelName}}",
    "scmanager": "SC Manager",
    "search": "Buscar",
    "second-property": "Segundo propietario",
    "segment": "Segmento",
    "segment.filters-message-first-part": "Encontrar clientes que cumplan",
    "segment.filters-message-second-part": "las siguientes condiciones",
    "segment.total_users_reached": "Total de usuarios alcanzados",
    "segment.warning_update_users": "La campa\u00f1a ser\u00e1 enviada diariamente a los nuevos usuarios que cumplan las siguientes condiciones",
    "segments.filters_needed": "Al menos un filtro es obligatorio",
    "segments.save_as": "Guardar como {{modelName}}",
    "segments.save_changes": "Guardar cambios",
    "segments.save_new": "Guardar como nuevo {{modelName}}",
    "segments.type_name": "Escribe el {{modelName}} nombre",
    "segments.warning": "Aviso!",
    "select": "Seleccionar",
    "select-building": "Selecciona edificio",
    "select-center": "Seleccionar un centro",
    "select-channel": "Selecciona el canal",
    "select-country": "Selecciona Pa\u00eds",
    "select-level": "Selecciona nivel",
    "select-shop": "Select shop",
    "select_all": "Seleccionar todo",
    "select-state": "Select state",
    "select_communication_required_attention": "Selecciona si la comunicaci\u00f3n requiere atenci\u00f3n urgente",
    "select_one_option": "Selecciona una opci\u00f3n",
    "sell-price": "Precio venta",
    "send": "Enviar",
    "send-email-template.help.multipleEmails": "Introduce m\u00faltiples direcciones de e-mail separadas por comas",
    "send_economic_proposal": "Env\u00edar propuesta econ\u00f3mica",
    "send_email": "Enviar e-mail",
    "september": "Septiembre",
    "september-short": "Sep",
    "service": "Servicio",
    "service.edit": "Editar servicio",
    "service.new": "Nuevo servicio",
    "services": "Servicios",
    "services-and-qualities": "Servicios y calidades",
    "sex": "G\u00e9nero",
    "shop": "Tienda",
    "shop-property": "Propiedades de la tienda",
    "shop-search": "Buscar tienda",
    "shop.all": "Todas",
    "shopMonth.state.closed": "Cerrado",
    "shopMonth.state.unclosed": "Abierto",
    "shopMonth.state.validated": "Validado",
    "shopMonth.state.without_sales": "Sin ventas",
    "shops": "Tiendas",
    "shops.not_found": "No se encontraron tiendas",
    "show-details": "Mostrar detalle",
    "show-children": "Mostrar hijos",
    "show-development": "Mostrar promoci\u00f3n",
    "show-price": "Mostrar precio",
    "since_last_visit": "Desde la \u00faltima visita (d\u00edas)",
    "single": "Soltero",
    "sold": "Vendido",
    "single_family": "Unifamiliar",
    "soluciona_errores": "Soluciones errores",
    "south": "Sur",
    "spending_receipts": "Recibos de gastos",
    "spreadsheet": "Hoja de c\u00e1lculo",
    "staff-level-sales": "Ventas",
    "staff-level-services": "Servicios",
    "staff-manager": "Staff managers",
    "staff-member": "Miembro del  equipo",
    "staff-members": "Miembros del  equipo",
    "staff-members.new": "Nuevo miembro del  equipo",
    "staff.level.admin": "Administrador",
    "staff.level.commerce": "Tienda",
    "staff.level.crmmanager": "Gestor CRM",
    "staff.level.crmoperator": "Operador CRM",
    "staff.level.manager": "Gestor",
    "staff.level.owner": "Propietario",
    "staff.level.scmanager": "SC Manager",
    "staff.level.storeManager": "Gestor tienda",
    "staff.level.weboperator": "Operador web",
    "stamps": "Sellos",
    "stamps.not-enough-stamps": "El usuario no tiene suficiente saldo para generar sellos",
    "stamps.preview-message": "Resumen de sellos",
    "stars": "estrellas",
    "start-date": "Fecha inicio",
    "state": "Estado",
    "status": "Estado",
    "stop": "Parar",
    "storage-plan": "Planos trasteros",
    "storage-rooms-number": "N\u00ba Trasteros",
    "store": "Tienda",
    "store-manager": "Gestor Tienda",
    "subtype": "Subtipo",
    "subtype.edit": "Editar subtipo",
    "subtype.name": "Nombre",
    "subtype.new": "Nuevo subtipo",
    "subtype.type": "Tipo",
    "subway": "Metro",
    "sunday": "Domingo",
    "sunday-short": "Dom",
    "supplier": "Proveedor",
    "supplier-contact.filters": "Filtros",
    "supplier-contact.new": "Nuevo contacto de proveedor",
    "supplier.edit": "Editar proveedor",
    "supplier.new": "Nuevo proveedor",
    "supplierRating": "Calificaci\u00f3n de proveedor",
    "suppliers.active": "Activo",
    "suppliers.filters": "Filtros",
    "suppliers.local": "Local",
    "suppliers.national": "Nacional",
    "suppliers.rating": "Calificaci\u00f3n",
    "surface": "Superficie (m2)",
    "table-list.not_found": "No hay elementos disponibles",
    "technical-data": "Dato t\u00e9cnico",
    "thecnical-data": "Datos t\u00e9cnicos",
    "threshold_active_check_in": "Umbral de espera activa (min)",
    "thumbnail": "Miniatura",
    "thursday": "Jueves",
    "thursday-short": "Juev",
    "ticket-amount": "Importe de ticket",
    "ticket.campaign": "Campa\u00f1a",
    "ticket.campaign-name": "Campa\u00f1a",
    "ticket.code": "C\u00f3digo",
    "ticket.created-at": "Fecha de creaci\u00f3n",
    "ticket.generate-error-message": "Hubo un problema al generar las papeletas y no se han creado. Por favor, p\u00f3ngase en contacto con el equipo de soporte.",
    "ticket.generate-success-message": "Las papeletas se han generado correctamente",
    "ticket.label": "Regla",
    "ticket.loyalty-card": "Tarjeta de fidelizaci\u00f3n",
    "ticket.pending-balance": "Saldo pendiente",
    "ticket.preview-message": "Resumen de papeletas",
    "ticket.raffle": "Sorteo",
    "ticket.rule": "Regla",
    "ticket.tickets-number": "Papeletas",
    "tickets.amount": "Cantidad",
    "tickets.code": "C\u00f3digo",
    "tickets.date": "Fecha",
    "tickets.not-enough-tickets": "El usuario no tiene suficiente saldo pendiente para generar tickets.",
    "tickets.resume": "Resumen",
    "tickets.salesman": "Vendedor",
    "tickets.shop-helper-message": "Solo puedes seleccionar tiendas que pertenezcan a las campa\u00f1as seleccionada",
    "tickets.shop-search": "Buscar tienda",
    "tickets.shop-search.no-shops": "No encontrada ninguna tienda en las campa\u00f1as seleccionadas",
    "title.communication.subtypes": "Subtipos de comunicaci\u00f3n",
    "title.header.bonus-requests": "Peticiones de bonificaci\u00f3n",
    "title.header.cms.commerce-stay-alerts": "Alerta de estancia en tienda",
    "title.header.cms.commerces": "Comercios",
    "title.header.communication": "Comunicaciones",
    "title.header.configuration": "Configuraci\u00f3n",
    "title.header.configuration.dashboard": "Configuraci\u00f3n del panel",
    "title.header.configuration.staff-members": "Miembros del  equipo",
    "title.header.crm.campaignRules": "Excepciones al sorteo",
    "title.header.crm.campaigns": "Campa\u00f1as",
    "title.header.crm.email-templates": "Plantillas de e-mail",
    "title.header.crm.users": "Clientes",
    "title.header.dashboards.show": "Panel",
    "title.header.desktop": "Escritorio",
    "title.header.emailing.campaigns": "Campa\u00f1as de e-mail",
    "title.header.files": "Gesti\u00f3n de archivos",
    "title.header.footfall": "Footfall",
    "title.header.guards": "Guardias",
    "title.header.intranet-sale": "Ventas",
    "title.header.intranet.sales-years": "Declaraci\u00f3n  de ventas  anual",
    "title.header.marketing-analytics.campaignActions": "Acciones de campa\u00f1a",
    "title.header.marketing-analytics.campaignGoals": "Objetivos de campa\u00f1a",
    "title.header.marketing-analytics.campaignRules": "Excepciones del sorteo",
    "title.header.marketing-analytics.campaigns": "Campa\u00f1as",
    "title.header.marketing-analytics.campaigntypes": "Tipos de campa\u00f1a",
    "title.header.marketing-analytics.lineitems": "Partidas",
    "title.header.marketing-analytics.services": "Servicios",
    "title.header.marketing-analytics.suppliercontacts": "Contactos proveedores",
    "title.header.marketing-analytics.suppliers": "Proveedores",
    "title.header.proposals": "Propuestas",
    "title.header.push-notifications": "Notificaciones push",
    "title.header.residential.campaigns": "Campa\u00f1as",
    "title.header.residential.developments": "Promociones",
    "title.header.residential.owners": "Propietarios",
    "title.header.sales": "Gesti\u00f3n de ventas",
    "title.header.residential.properties": "Inmuebles",
    "title.header.subtypes": "Tipos de comunicaci\u00f3n",
    "title.header.testing": "T\u00edtulo de prueba",
    "token": "Token",
    "total": "Total",
    "total_sales": "Total en ventas",
    "train": "Tren",
    "tuesday": "Martes",
    "tuesday-short": "Mart",
    "type": "Tipo",
    "unit-type": "Tipo unidad",
    "unpublish": "Retirar",
    "upcoming-commercialization": "Pr\u00f3xima comercializaci\u00f3n",
    "update-password.server-problem": "Hay un problema con el servidor. Contacta con el equipo de soporte. Gracias",
    "upload": "Upload",
    "urgent": "Urgente",
    "useful-surface": "Sup. \u00datil",
    "user": "Usuario",
    "user-error": "Something is wrong. Please check the following errors: ",
    "user.actions": "Acciones",
    "user.address": "Direcci\u00f3n",
    "user.app-notifications-check": "Quiero recibir notificaciones de la app",
    "user.bday-days": "Fecha de nacimiento (d\u00edas)",
    "user.birthdate": "Fecha de nacimiento",
    "user.campaigns": "Campa\u00f1as",
    "user.can-access-bonus-request": "Puede acceder a la solicitud de bonificaci\u00f3n",
    "user.channels": "Canales",
    "user.child-bday": "Fecha de nacimiento ni\u00f1os (d\u00edas)",
    "user.child-in-play-center-days": "Ni\u00f1os en la ludoteca (d\u00edas)",
    "user.children_number": "N\u00famero de ni\u00f1os",
    "user.city": "Ciudad",
    "user.code": "C\u00f3digo de cliente",
    "user.company": "Empresa",
    "user.countries": "Pa\u00edses",
    "user.cp": "C\u00f3digo postal",
    "user.created_at": "Fecha de registro{{mandatory}}",
    "user.days-until-campaign-expiration": "d\u00edas hasta la expiraci\u00f3n de la campa\u00f1a",
    "user.dni": "C.I.F.",
    "user.document_type": "Tipo de documento",
    "user.electronic_communications_phrase": "Quiero recibir comunicaciones electr\u00f3nicas comerciales",
    "user.email": "Email",
    "user.general_data": "Datos generales",
    "user.gdpr_phrase": "Acepto politicas de uso y privacidad",
    "user.in-campaign": "En campa\u00f1a",
    "user.large-family": "Plurifamiliar",
    "user.last_name": "Apellido",
    "user.manual-campaigns": "Campa\u00f1as manuales",
    "user.marital_status": "Estado civil",
    "user.marketing_data": "Marketing",
    "user.most-purchased-subfamily": "Subfamilia m\u00e1s comprada (d\u00edas)",
    "user.name": "Nombre",
    "user.nationality": "Nacionalidad",
    "user.nationality-search": "Buscar nacionalidad",
    "user.no-assigned-campaign-message": "No hay campa\u00f1as segmentadas asignadas",
    "user.no-assigned-segment-message": "No segmentos asignados",
    "user.notes": "Notas",
    "user.number-receipt-days": "N\u00famero de recibos (\u00faltimos d\u00edas)",
    "user.other_data": "Otros datos",
    "user.partners_electronic_communications_phrase": "Quiero recibir comunicaciones electr\u00f3nicas de terceros",
    "user.person_type": "Tipo de persona",
    "user.phone": "Tel\u00e9fono",
    "user.province": "Provincia",
    "user.province-search": "Buscar provincia",
    "user.purchaser-type": "Tipo de comprador",
    "user.redeemable-stamps": "Sellos canjeables",
    "user.redeemable-stamps-range": "Sellos canjeables (rango)",
    "user.redeemed-loyalty-cards": "Tarjetas de fidelizaci\u00f3n canjeadas",
    "user.redeemed-loyalty-cards-range": "Tarjetas de fidelizaci\u00f3n canjeadas (rango)",
    "user.redeemed-reward-days": "Recompensas redimibles (rango)",
    "user.redeemed-rewards": "Recompensas canjeadas",
    "user.redeemed-rewards-range": "Recompensas canjeadas",
    "user.reference-building": "Edificio de referencia",
    "user.reference-commerce": "Comercio de referencia",
    "user.salesman": "Vendedor",
    "user.secondary_phone": "Tel\u00e9fono secundario",
    "user.segment": "Segmento",
    "user.segmented-campaigns": "Campa\u00f1as segmentadas asignadas autom\u00e1ticamente",
    "user.select_document_type": "Selecciona tipo de documento",
    "user.select_marital_status": "Selecciona estado civil",
    "user.select_sex": "Selecciona g\u00e9nero",
    "user.seniority": "Antig\u00fcedad",
    "user.sex": "G\u00e9nero",
    "user.spending-receipt-days": "Recibos de gastos (\u00faltimos d\u00edas)",
    "user.user-segments": "Segmentos de usuario",
    "user.user-types": "Tipos de usuario",
    "user_types": "Tipos de usuario",
    "users.amount": "Cantidad",
    "users.campaigns": "Campa\u00f1as",
    "users.code": "C\u00f3digo",
    "users.commerce": "Tienda",
    "users.date": "Fecha",
    "users.edit": "Editar cliente",
    "users.general_data": "Datos generales",
    "users.loyalty": "Loyalty",
    "users.new": "Nuevo cliente",
    "users.not-has-email-warning-message": "Se recomienda a\u00f1adir el correo electr\u00f3nico del usuario",
    "users.receipts": "Facturas",
    "users.sale_process": "Proceso de venta",
    "users.warning": "Aviso",
    "usertypes": "Tipo de usuario",
    "value": "Valor",
    "vat-number": "NIF\/CIF",
    "vat_number": "D.N.I.",
    "video": "Video",
    "visibility": "Visibilidad",
    "visit_building": "Visita al edificio",
    "visits": "Visitas",
    "visits.recurrence": "Recurrencia",
    "want_continue": "\u00bfEst\u00e1 seguro que quiere continuar?",
    "want_duplicate": "\u00bfEst\u00e1s seguro de querer duplicar {{modelName}} {{name}}?",
    "want_expire": "\u00bfRealmente quieres expirar el {{modelName}} {{name}}?",
    "want_reject_publish": "\u00bfRealmente desea rechazar la publicaci\u00f3n de este {{modelName}} {{name}}?",
    "want_remove": "\u00bfEst\u00e1s seguro de querer eliminar este {{modelName}}?",
    "want_request": "\u00bfRealmente desea solicitar la publicaci\u00f3n de este {{modelName}} {{name}}?",
    "want_save_without_gdpr_or_electronic_communications": "No se ha marcado \"T\u00e9rmino y condiciones\" o \"Env\u00edo de comunicaciones\". \u00bfQuieres continuar?",
    "want_stop": "\u00bfRealmente quieres parar el {{modelName}} {{name}}?",
    "want_unpublish": "\u00bfEst\u00e1s seguro de querer ocultar {{modelName}} {{name}}?",
    "warning": "Aviso",
    "warning-residential-change-text": "Los cambios pueden afectar a servicios externos. Quiere continuar?",
    "waterkmark": "Watermark",
    "web": "Web",
    "web-image": "Imagen web",
    "web-operator": "Operador web",
    "wednesday": "Mi\u00e9rcoles",
    "wednesday-short": "Mier",
    "week": "Semana",
    "weekday.friday": "Viernes",
    "weekday.monday": "Lunes",
    "weekday.saturday": "S\u00e1bado",
    "weekday.sunday": "Domingo",
    "weekday.thursday": "Jueves",
    "weekday.tuesday": "Martes",
    "weekday.wednesday": "Mi\u00e9rcoles",
    "west": "Oeste",
    "wizard.next_step": "Siguiente",
    "wizard.prev_step": "Anterior",
    "woman": "Mujer",
    "yardi": "Yardi",
    "yardi-code": "C\u00f3digo Yardi",
    "yardi.continue_export": "Continuar con la exportaci\u00f3n",
    "yardi.shop_property_error": "La tienda necesita una propiedad vinculada",
    "yardi.tenant_code_error": "La tienda necesita un c\u00f3digo de inquilino espec\u00edfico",
    "yardi.warn_errors_exporting": "Hay errores en la exportaci\u00f3n. Por favor, rev\u00edselos antes de continuar.",
    "year": "A\u00f1o",
    "yen": "Yen \u00a5",
    "yes": "Si",
    "youtube.url": "URL Youtube",
    "zip-code": "C.Postal",
    "zone": "Zona",
    "zone-1": "Zona 1",
    "zone-2": "Zona 2",
    "zone-3": "Zona 3",
    "zone-4": "Zona 4",
    "zone-services": "Zona servicios",
    "select_month": "Selecciona un mes",
    "select_year": "Selecciona un a\u00f1o",
    "legal_person": "Persona jur\u00eddica",
    "residential.multimedia_files": "Archivos multimedia",
    "segments.save_new_segment": "Guardar como nuevo segmento",
    "save_segment": "Guardar segmento",
    "segments.save_as_segment": "Guardar como segmento",
    "map-description": "Descripci\u00f3n del mapa",
    "costa": "Costa",
    "available-premise": "Local disponible",
    "user.age": "Edad",
    "users.age.under-35": "Menos de 35",
    "users.age.35-45": "35-45",
    "users.age.50-65": "50-65",
    "users.age.over-65": "M\u00e1s de 65",
    "user.select_age": "Selecciona edad",
    "users.nationality.spanish": "Espa\u00f1ola",
    "users.nationality.foreigner": "Extranjero",
    "user.select_nationality": "Selecciona nacionalidad",
    "user.civil_status": "Estado civil",
    "user.select_civil_status": "Selecciona estado civil",
    "users.civil-status.single": "Soltero",
    "users.civil-status.married": "Casado",
    "users.civil-status.divorced": "Divorciado",
    "users.civil-status.widower": "Viudo",
    "user.foreign_nationality": "Nacionalidad extranjera",
    "user.select_foreign_nationality": "Selecciona nacionalidad extranjera",
    "users.foreign-nationality.french": "Franc\u00e9s",
    "users.foreign-nationality.portuguese": "Portugu\u00e9s",
    "users.foreign-nationality.italian": "Italiano",
    "users.foreign-nationality.united-kingdom": "Reino Unido",
    "users.foreign-nationality.german": "Alem\u00e1n",
    "users.foreign-nationality.mexican": "Mexicano",
    "users.foreign-nationality.brazilian": "Brasile\u00f1o",
    "user.lead_origin": "Origen del lead",
    "user.select_lead_origin": "Selecciona origen del lead",
    "users.lead-origin.web_cbre": "Web CBRE",
    "users.lead-origin.landing_origami": "Landing promoción",
    "users.lead-origin.valla": "Valla",
    "users.lead-origin.referenced": "Referido",
    "users.lead-origin.event": "Evento",
    "users.lead-origin.social_network": "Red social",
    "users.lead-origin.emailing": "Emailing",
    "users.lead-origin.developer_website": "Web promotor",
    "users.lead-origin.press": "Prensa",
    "users.lead-origin.selling_point": "Punto de venta",
    "users.lead-origin.idealista": "Idealista",
    "user.typology": "Tipolog\u00eda",
    "user.select_typology": "Selecciona tipolog\u00eda",
    "users.typology.multi-family": "Plurifamiliar",
    "users.typology.single-family": "Unifamiliar",
    "user.reason_for_purchase": "Motivo de compra",
    "user.select_reason_for_purchase": "Selecciona motivo de compra",
    "users.reason-for-purchase.first-home": "Primera vivienda",
    "users.reason-for-purchase.second-home": "Segunda vivienda",
    "users.reason-for-purchase.investment": "Inversi\u00f3n",
    "users.reason-for-purchase.replacement": "Remplazo",
    "user.state": "Estado",
    "user.select_state": "Selecciona estado",
    "users.state.monitoring": "Seguimiento",
    "users.state.discarded": "Descartado",
    "users.state.pre-registration": "Preinscripci\u00f3n",
    "users.state.incorporation": "Incorporaci\u00f3n",
    "users.state.writing": "Escritura",
    "user.visit": "Visita",
    "user.source_of_funds": "Origen de fondos",
    "user.reason_for_discard": "Motivo de descarte",
    "user.select_reason_for_discard": "Selecciona motivo de descarte",
    "users.reason-for-discard.location": "Ubicaci\u00f3n",
    "users.reason-for-discard.price": "Precio",
    "users.reason-for-discard.surface": "Superficie",
    "users.reason-for-discard.deadline": "Plazo",
    "users.reason-for-discard.buy_competition": "Compra competencia",
    "users.reason-for-discard.other": "Otro",
    "users.source-of-funds.own": "Propio",
    "users.source-of-funds.loan": "Pr\u00e9stamo",
    "user.select_source_of_funds": "Selecciona origen de fondos",
    "user.number_of_rooms": "N\u00famero de habitaciones",
    "user.select_number_of_rooms": "Selecciona n\u00famero de habitaciones",
    "users.rooms.one": "1",
    "users.rooms.two": "2",
    "users.rooms.tree": "3",
    "users.rooms.four": "4",
    "users.rooms.five": "5",
    "property.add_residential-development": "A\u00f1adir promoci\u00f3n",
    "crm.users.created-at": "Fecha de alta",
}
;